import { Route } from "react-router-dom";
import Contact from "../components/Contact";
import withFirebase from "../hoc/withFirebase";
import withUser from "../hoc/withUser";
import React, { Component } from "react";
import SubjectMaster from "../components/SubjectMaster";
import firebase from "firebase/app";

class ContactContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: [],
      selectedSubjectInstitutedata: "",
    };
  }

  UNSAFE_componentWillMount() {
    this.fetchSubjects();
  }

  fetchSubjects = (classId) => {
    this.props.db
      .collection("subjectsMaster")
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        const subjects = [];
        querySnapshot.forEach((doc) => {
          console.log(doc, "docdocdoc");
          const singlesubjects = doc.data();
          singlesubjects.id = doc.id;
          if (doc.data().instituteId) {
            subjects.push(singlesubjects);
          } else {
            subjects.push(singlesubjects);
          }
        });
        this.setState({ subjects: subjects, isLoading: false });
      });
  };
  fetchInstituteName = (subjectId) => {
    console.log(subjectId, "subjectIdsubjectId");
    this.props.db
      .collection("institutes")
      .doc(subjectId)
      .onSnapshot((querySnapshot) => {
        console.log(
          querySnapshot,
          querySnapshot.data(),
          "querySnapshot.data()querySnapshot.data()querySnapshot.data()querySnapshot.data()"
        );
        const selectedSubjectInstitutedata = querySnapshot.data();
        // selectedSubjectInstitutedata.id = querySnapshot.id;
        this.setState({ selectedSubjectInstitutedata });
      });
  };
  saveData = (data, selectedSubject) => {
    console.log(data, "savingggg");
    //	docRef.doc(userRef.id).set(
    this.props.db
      .collection("subjectsMaster")
      .doc(selectedSubject.id)
      .update(data)
      .then(() => {
        console.log("added");
      })
      .catch((error) => {
        console.log("errorMsg = ", error.message);
      });
  };
  addData = (subjectArray) => {
    subjectArray.map((singleSub) => {
      this.props.db.collection("subjectsMaster").add({
        name: singleSub,
        status: "approved",
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    });
  };
  render() {
    return (
      <Route
        exact
        path={["/subjects", "/subjects/:subjectTypes/:id", "/subjects/new"]}
        render={(props) => (
          <SubjectMaster
            fetchSubjects={this.fetchSubjects}
            fetchInstitute={this.fetchInstitute}
            saveData={this.saveData}
            addData={this.addData}
            {...this.props}
            {...this.state}
            fetchInstituteName={this.fetchInstituteName}
          />
        )}
      />
    );
  }
}

export default withFirebase(withUser(ContactContainer));

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Moment from "moment";
import { InputBase, TextField, InputAdornment } from "@material-ui/core";
const styles = {
  heading: {
    height: "65px",
    display: "flex",
    alignItems: "center",
  },
  inputText: (mobile) => ({
    padding: mobile ? "" : "10px",
    backgroundColor: "white",
  }),
  mobileComponentDiv: {
    padding: "0 7% 2px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  webComponentDiv: {
    padding: "0 10% 2px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  sessionTimeHeading: {
    fontSize: "0.9rem",
    color: "#757575",
    fontFamily: "Poppins",
  },
  radioGroupStyle: {
    display: "flex",
    flexDirection: "inherit",
    marginLeft: "20px",
  },
  mobileActionDiv: {
    position: "absolute",
    bottom: "20px",
    width: "50%",
    right: "0",
  },
  webActionDiv: {
    position: "absolute",
    bottom: "5px",
    right: "0",
    width: "80%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#A9A9A9",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#A9A9A9",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#A9A9A9",
      },
      "&:hover fieldset": {
        borderColor: "#A9A9A9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#A9A9A9",
      },
    },
  },
})(TextField);
export class ReadingTime extends Component {
  componentWillMount() {
    if (this.props.totalReadTime) {
      this.setState({
        text: this.props.time
          ? this.props.time
          : this.props.totalReadTime.readingTime
          ? this.props.totalReadTime.readingTime
          : "",
      });
    }
  }
  render() {
    const { totalReadTime } = this.props;
    return (
      <div>
        <CssTextField
          inputProps={{
            style: styles.inputText(),
          }}
          type="number"
          style={{
            width: "100%",
            background: "white",
            // height: "56px",
            marginTop: "0",
          }}
          id="outlined-helperText"
          value={totalReadTime.minutes ? totalReadTime.minutes : ""}
          //onChange={this.handleChange("sessionDuration")}
          defaultValue="30"
          variant="outlined"
          //   label="Duration"
          //autoFocus={this.state.sessionNow}
          InputLabelProps={{
            shrink: true,
          }}
          onFocus={() => {
            if (window.cordova) {
              this.setState({ onFocusStatus: true });
            }
          }}
          onBlur={() => {
            if (window.cordova) {
              this.setState({ onFocusStatus: false });
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <div style={{ color: "rgba(0, 0, 0, 0.38)" }}>min</div>
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  }
}

export default ReadingTime;

import { Route } from "react-router-dom";
import Institutes from "../components/Institutes";

import withFirebase from "../hoc/withFirebase";
import withUser from "../hoc/withUser";
import React, { Component } from "react";

class InstitutesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      institutes: [],
      teachers: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.fetchinstitutes();
  }

  fetchinstitutes = () => {
    this.props.db
      .collection("institutes")
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        const institutes = [];
        querySnapshot.forEach((doc) => {
          const singleinstitutes = doc.data();
          singleinstitutes.id = doc.id;
          institutes.push(singleinstitutes);
        });
        this.setState({ institutes, isLoading: false });
      });
    this.props.db
      .collection("users")
      .where("userType", "==", "teacher")
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        const teachers = [];
        querySnapshot.forEach((doc) => {
          const singleteachers = doc.data();
          singleteachers.id = doc.id;
          if (doc.data().profile) {
            singleteachers.userName =
              doc.data().profile.firstName + " " + doc.data().profile.lastName;
            // singleteachers.subjectName = doc.data().classSubjects[0].subjectName;
          } // singleteachers.className = doc.data().classSubjects[0].className;
          teachers.push(singleteachers);
        });
        this.setState({ teachers, isLoading: false });
      });
  };
  fetchInstitutesClasses = (instituteId) => {
    this.props.db
      .collection("classes")
      .where("instituteId", "==", instituteId)
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        const classes = [];
        querySnapshot.forEach((doc) => {
          const singleclasses = doc.data();
          singleclasses.id = doc.id;

          classes.push(singleclasses);
        });
        this.setState({ classdata: classes, isLoading: false });
      });
  };
  fetchInstitutesStudents = (classId) => {
    this.props.db
      .collection("classStudents")
      .where("classId", "==", classId)
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        const classStudents = [];
        querySnapshot.forEach((doc) => {
          const singleclassStudents = doc.data();
          singleclassStudents.id = doc.id;
          classStudents.push(singleclassStudents);
        });
        this.setState({ students: classStudents, isLoading: false });
      });
  };

  fetchClassTeacher = (classId) => {
    this.props.db
      .collection("subjects")
      .where("classId", "==", classId)
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        const subjects = [];
        querySnapshot.forEach((doc) => {
          const singlesubjects = doc.data();
          singlesubjects.id = doc.id;
          subjects.push(singlesubjects);
        });
        this.setState({ subjects: subjects, isLoading: false });
      });
  };
  fetchInstitutesFiles = (classId) => {
    console.log(classId, "classIdd");
    this.props.db
      .collection("files")
      .where("classId", "==", classId)
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        const files = [];
        querySnapshot.forEach((doc) => {
          const singlefiles = doc.data();
          singlefiles.id = doc.id;
          files.push(singlefiles);
        });
        this.setState({ filesdata: files, isLoading: false });
      });
  };
  fetchInstitutesSessions = (classId) => {
    this.props.db
      .collection("sessions")
      .where("classId", "==", classId)
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        const sessions = [];
        querySnapshot.forEach((doc) => {
          const singlesessions = doc.data();
          singlesessions.id = doc.id;
          sessions.push(singlesessions);
        });
        this.setState({ sessionsdata: sessions, isLoading: false });
      });
  };
  fetchClassSubjects = (classId) => {
    this.props.db
      .collection("subjects")
      .where("classId", "==", classId)
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        const subjects = [];
        querySnapshot.forEach((doc) => {
          const singlesubjects = doc.data();
          singlesubjects.id = doc.id;
          subjects.push(singlesubjects);
        });
        this.setState({ subjects: subjects, isLoading: false });
      });
  };
  enterSubjectChat = (data) => {
    if (data) {
      this.props.db
        .collection(`subjects/${data.id}/subjectMessages`)
        .orderBy("createdAt", "asc")
        .onSnapshot((querySnapshot) => {
          const subjectMesseges = [];
          querySnapshot.forEach((doc) => {
            if (doc.exists) {
              const data = doc.data();
              data.id = doc.id;
              subjectMesseges.push(data);
            }
          });

          this.setState({ subjectMesseges: subjectMesseges });
        });
    }
  };
  enterClassAnnouncementsChat = (data) => {
    this.props.db
      .collection(`classes/${data.id}/classMessages`)
      .orderBy("createdAt", "asc")
      .onSnapshot((querySnapshot) => {
        const groupMesseges = [];
        querySnapshot.forEach((doc) => {
          if (doc.exists) {
            const data = doc.data();
            data.id = doc.id;
            groupMesseges.push(data);
          }
        });
        this.setState({ groupMesseges: groupMesseges });
      });
  };
  render() {
    return (
      <Route
        exact
        path={["/institutes", "/institutes/user/:id", "/institutes/new"]}
        render={(props) => (
          <Institutes
            fetchInstitutesClasses={this.fetchInstitutesClasses}
            fetchInstitutesStudents={this.fetchInstitutesStudents}
            fetchClassTeacher={this.fetchClassTeacher}
            fetchInstitutesFiles={this.fetchInstitutesFiles}
            fetchInstitutesSessions={this.fetchInstitutesSessions}
            fetchClassSubjects={this.fetchClassSubjects}
            enterSubjectChat={this.enterSubjectChat}
            enterClassAnnouncementsChat={this.enterClassAnnouncementsChat}
            {...this.props}
            {...this.state}
          />
        )}
      />
    );
  }
}

export default withFirebase(withUser(InstitutesContainer));

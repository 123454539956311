import React from 'react';

export default class AppIconClass extends React.Component {
	render() {
		return (
			<svg
				style={{ marginRight: this.props.dtOnboarding ? '13%' : 0 }}
				width="100%"
				height="100%"
				viewBox="0 0 1073.02 864.138">
				<g id="Icon_White" data-name="Icon White" transform="translate(-859.418 -1284.999)">
					<path
						id="Path_12"
						data-name="Path 12"
						d="M752.971,165.066l339.139,146.726L729.249,524.413,602.731,339.029Z"
						transform="translate(838.2 1354.936)"
						fill="#e3d691"
					/>
					<path
						id="Path_10"
						data-name="Path 10"
						d="M673.459,113l140.575,65.4S741.99,288.223,760.44,460.427c0,0,20.208-144.968,144.969-245.128l101.038,48.323S848.106,368.359,876.318,570.3c15.262,109.247,89.192,178.078,154.817,218.852l-95.424,58.811S723.381,836.08,640.23,608.9c0,0-1.485,148.483,206.391,291.026l-127.7,77.211S469.475,844.989,472.445,515.357c1.518-168.441,95.311-301.212,187.671-391.755Z"
						transform="translate(387 1171.999)"
						fill="#fff"
					/>
					<path
						id="Path_11"
						data-name="Path 11"
						d="M691.411,155.543,798.6,202.109s79.142,51.031,49.2,117.858-49.2,57.861-99.282,62.254-53.6,66.9-32.508,101.165,42.377,50.824,88.064,49.945l-88.064,52.725s-123-88.738-123-222.285S691.411,155.543,691.411,155.543Z"
						transform="translate(800.44 1322.286)"
						fill="#fff"
					/>
					<path
						id="Path_14"
						data-name="Path 14"
						d="M776.883,221.927c-3.9-.676-71.676-30.455-71.676-30.455s-22.3,36.653,0,70.445Z"
						transform="translate(1155.555 1445.466)"
						fill="#2d2727"
					/>
				</g>
			</svg>
		);
	}
}

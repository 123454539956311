import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Moment from "moment";
import { InputBase, TextField, InputAdornment } from "@material-ui/core";
const styles = {
  heading: {
    height: "65px",
    display: "flex",
    alignItems: "center",
  },
  inputText: (mobile) => ({
    padding: mobile ? "" : "10px",
    backgroundColor: "white",
  }),
  mobileComponentDiv: {
    padding: "0 7% 2px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  webComponentDiv: {
    padding: "0 10% 2px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  sessionTimeHeading: {
    fontSize: "0.9rem",
    color: "#757575",
    fontFamily: "Poppins",
  },
  radioGroupStyle: {
    display: "flex",
    flexDirection: "inherit",
    marginLeft: "20px",
  },
  mobileActionDiv: {
    position: "absolute",
    bottom: "20px",
    width: "50%",
    right: "0",
  },
  webActionDiv: {
    position: "absolute",
    bottom: "5px",
    right: "0",
    width: "80%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#A9A9A9",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#A9A9A9",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#A9A9A9",
      },
      "&:hover fieldset": {
        borderColor: "#A9A9A9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#A9A9A9",
      },
    },
  },
})(TextField);
export class PublishingDate extends Component {
  constructor(props) {
    super(props);
    this.state = { date: "" };
  }
  handleChange = (event) => {
    this.setState({ date: event.target.value });
  };
  render() {
    const { date } = this.state;
    return (
      <div>
        <CssTextField
          id="date"
          type="date"
          inputProps={{
            style: styles.inputText(),
          }}
          InputLabelProps={{
            shrink: true,
          }}
          //disabled={this.state.sessionTime === "now"}
          //autoFocus={this.state.sessionLater}
          style={{
            width: "100%",
            marginTop: "0",
            backgroundColor: " white",
            // height: "56px",
          }}
          required
          onChange={(e) => this.handleChange(e)}
          defaultValue={
            date
              ? `${Moment(date).format("YYYY-MM-DD")}`
              : `${Moment(new Date()).format("YYYY-MM-DD")}`
          }
          value={
            date
              ? `${Moment(date).format("YYYY-MM-DD")}`
              : `${Moment(new Date()).format("YYYY-MM-DD")}`
          }
          id="outlined-helperText"
          //   label="Date"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {/* <div style={{ marginRight: "" }}>
                  {Moment(this.state.sessionDate).format("dddd")}
                </div> */}
              </InputAdornment>
            ),
          }}
          variant="outlined"
          onFocus={() => {
            if (window.cordova) {
              this.setState({ onFocusStatus: true });
            }
          }}
          onBlur={() => {
            if (window.cordova) {
              this.setState({ onFocusStatus: false });
            }
          }}
        />
      </div>
    );
  }
}

export default PublishingDate;

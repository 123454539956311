import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import withUser from "./hoc/withUser";
import withAuthentication from "./hoc/withAuthentication";
import LoginContainer from "./containers/Login";
import InstitutesContainer from "./containers/InstitutesContainer";
import AllTeachersList from "./containers/AllTeachersList";
import ContactContainer from "./containers/Contact";
import UserAndPermissions from "./containers/UserAndPermissions";
import Loader from "./components/Loader";
import Blogs from "./containers/Blogs";
import SubjectMaster from "./containers/SubjectMaster";
import WebsiteContactDetails from "./containers/WebsiteContactDetails";
import FaqDetails from "./containers/FaqDetails";
// If we won't accept anonymous user as logged in user
const Secure = ({ component: Component, user, ...props }) => (
  <Route
    {...props}
    render={(props) =>
      user && !user.isAnonymous ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

// Loader while login redirect
const RouteLoader = ({ component: Component, user, ...props }) => (
  <Route
    {...props}
    render={(props) =>
      user ? <Redirect to="/institutes" /> : <Component {...props} />
    }
  />
);

const App = ({ user }) => {
  return (
    <Router>
      <Switch>
        <Secure
          path="/institutes"
          component={InstitutesContainer}
          user={user}
        />
        <Secure path="/contact" component={ContactContainer} user={user} />
        <Secure
          path="/allteacherslist"
          component={AllTeachersList}
          user={user}
        />
        <RouteLoader path="/authReloadWait" component={Loader} user={user} />
        <Route
          path="/subjects"
          render={(props) => <SubjectMaster {...props} />}
        />
        <Route path="/blogs" render={(props) => <Blogs {...props} />} />
        <Route
          path="/websiteContacts"
          render={(props) => <WebsiteContactDetails {...props} />}
        />
        <Route path="/faq's" render={(props) => <FaqDetails {...props} />} />
        <Route
          path="/userandpermmision"
          render={(props) => <UserAndPermissions {...props} />}
        />
        <Route
          exact
          path="/login"
          render={(props) => <LoginContainer {...props} />}
        />
        <Redirect from="/" to="/institutes" />
      </Switch>
    </Router>
  );
};

export default withAuthentication(withUser(App));

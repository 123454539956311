import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import config from "./config";
import configWebsite from "./configWebsite";
// this.app = admin.initializeApp(config);
// this.appWeb = admin.initializeApp(configWebsite, "other");
// this.auth = this.app.auth();
// this.db = this.app.firestore();
// this.authWeb = this.appWeb.auth();
// this.dbWeb = this.appWeb.firestore();
class Firebase {
  constructor() {
    this.app = firebase.initializeApp(config);
    this.appWeb = firebase.initializeApp(configWebsite, "other");
    this.auth = this.app.auth();
    this.authWeb = this.appWeb.auth();
    this.db = this.app.firestore();
    this.dbWeb = this.appWeb.firestore();
    this.sendInvitationAdmin = firebase
      .functions()
      .httpsCallable("sendInvitationAdmin");
    this.sendEmail = firebase.functions().httpsCallable("sendEmail");
    // this.sendEmail = firebase.functions().httpsCallable("sendEmail");
    this.log = console.error;
    this.appWeb
      .firestore()
      .enablePersistence()
      .then(() => {})
      .catch(this.log);
    this.app
      .firestore()
      .enablePersistence()
      .then(() => {})
      .catch(this.log);
  }
  googleSignIn = (props) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    this.auth
      .signInWithRedirect(provider)
      .then(() => firebase.auth().getRedirectResult())
      .then(() => {})
      .catch((error) => {
        props.history.push("/login");
        this.log(error);
      });
  };

  logOut = (props) =>
    this.auth.signOut().then(() => {
      localStorage.removeItem("user"); // Remove authenticated user from localstorage
      props.history.push("/"); // After logout redirect to app's entry point
    }, this.log); // then(Success callback, Error Callback)

  signInAnonymously = () => this.auth.signInAnonymouslyAndRetrieveData();
}

export default Firebase;

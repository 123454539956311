import React, { useState, useEffect } from "react";
import "./App.css";
import { forwardRef } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "react-avatar";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import { withStyles } from "@material-ui/core/styles";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import withFirebase from "../hoc/withFirebase";
import withUser from "../hoc/withUser";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  paper: {
    background: "red",
    backgroundColor: "red",
    ".MuiPaper-root": {
      background: "red",
      backgroundColor: "red",
    },
  },
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const api = axios.create({
  baseURL: `https://reqres.in/api`,
});

function validateEmail(email) {
  const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}

function SessionTable(props) {
  var columns = [
    // { title: 'id', field: 'id', hidden: true },
    // {
    // 	title: 'Avatar',
    // 	render: (rowData) => (
    // 		<Avatar
    // 			maxInitials={1}
    // 			size={40}
    // 			round={true}
    // 			name={rowData === undefined ? ' ' : rowData.first_name}
    // 		/>
    // 	)
    // },
    { title: "SubjectName", field: "subjectName" },
    { title: "FileName", field: "name" },
    { title: "FileType", field: "fileType" },
    { title: "ViewCount", field: "viewCount" },
    { title: "TeacherNumber", field: "teacherId" },
  ];

  const [data, setData] = useState([]); //table data

  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    api
      .get("/users")
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        console.log("Error");
      });
  }, []);

  const handleRowUpdate = (newData, oldData, resolve) => {
    // validation;
    // console.log(newData, oldData, resolve);
    let errorList = [];
    if (newData.subjectName === "") {
      errorList.push("Please enter subjectName ");
    }
    if (newData.name === "") {
      errorList.push("Please enter name");
    }
    if (newData.fileType === "") {
      errorList.push("Please enter fileType");
    }
    if (newData.viewCount === "") {
      errorList.push("Please enter viewCount");
    }
    if (newData.teacherId === "") {
      errorList.push("Please enter teacher number");
    }
    // || validateEmail(newData.name) === false
    if (errorList.length < 1) {
      props.db
        .collection("files")
        .doc(oldData.id)
        .update({
          subjectName: newData.subjectName,
          name: newData.name,
          fileType: newData.fileType,
          viewCount: newData.viewCount,
          teacherId: newData.teacherId,
        })
        .then((res) => {
          resolve();
          setIserror(false);
          setErrorMessages([]);
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

  const handleRowAdd = (newData, resolve) => {
    //validation
    let errorList = [];
    if (newData.first_name === undefined) {
      errorList.push("Please enter first name");
    }
    if (newData.last_name === undefined) {
      errorList.push("Please enter last name");
    }
    if (newData.email === undefined || validateEmail(newData.email) === false) {
      errorList.push("Please enter a valid email");
    }

    if (errorList.length < 1) {
      //no error
      api
        .post("/users", newData)
        .then((res) => {
          let dataToAdd = [...data];
          dataToAdd.push(newData);
          setData(dataToAdd);
          resolve();
          setErrorMessages([]);
          setIserror(false);
        })
        .catch((error) => {
          setErrorMessages(["Cannot add data. Server error!"]);
          setIserror(true);
          resolve();
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

  const handleRowDelete = (oldData, resolve) => {
    let errorList = [];

    props.db
      .collection("files")
      .doc(oldData.id)
      .delete()
      .then((res) => {
        resolve();
      });
  };

  return (
    <MuiThemeProvider theme={theme}>
      {" "}
      <CssBaseline />
      <div
        style={{
          width: "100%",
          // height : 'calc(100vh - 303px)',
          height: "200px",
          lineHeight: "0.5rem",
          padding: "0px",
          // display       : 'flex',
          // flexDirection : 'row'
        }}
      >
        <Grid
          style={{
            width: "100%",
            height: "100%",
            lineHeight: "0.5rem",
            padding: "0px",
          }}
        >
          {/* <Grid item xs={3} /> */}
          {/* <Grid item> */}
          {/* //xs={6} */}
          <div>
            {iserror && (
              <Alert severity="error">
                {errorMessages.map((msg, i) => {
                  return <div key={i}>{msg}</div>;
                })}
              </Alert>
            )}
          </div>
          <MaterialTable
            style={{ width: "100%", lineHeight: "0.5rem", padding: "0px" }}
            title="Files Data Table"
            columns={columns}
            data={props.filesdata}
            options={{
              pageSize: 3,
              headerStyle: { padding: "0px" },
              cellStyle: { padding: "0px" },
            }}
            icons={tableIcons}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  handleRowUpdate(newData, oldData, resolve);
                }),
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  handleRowAdd(newData, resolve);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  handleRowDelete(oldData, resolve);
                }),
            }}
          />
        </Grid>
        {/* <Grid item xs={3} /> */}
        {/* </Grid> */}
      </div>
    </MuiThemeProvider>
  );
}

export default withFirebase(withUser(SessionTable));

// const config = {
//   apiKey: "AIzaSyAIMHSC90kEi-gTaXSYITQ5v4fcaPb5KPw",
//   authDomain: "netpractice-app.firebaseapp.com",
//   databaseURL: "https://netpractice-app.firebaseio.com",
//   projectId: "netpractice-app",
//   storageBucket: "netpractice-app.appspot.com",
//   messagingSenderId: "206821338562",
//   appId: "1:206821338562:web:a741edecc178fd3e8aba5b",
//   measurementId: "G-N6TBHPR8G8",
// };

// export default config;

const config = {
  apiKey: "AIzaSyAIMHSC90kEi-gTaXSYITQ5v4fcaPb5KPw",
  authDomain: "netpractice-app.firebaseapp.com",
  databaseURL: "https://netpractice-app.firebaseio.com",
  projectId: "netpractice-app",
  storageBucket: "netpractice-app.appspot.com",
  messagingSenderId: "206821338562",
  appId: "1:206821338562:web:a741edecc178fd3e8aba5b",
  measurementId: "G-N6TBHPR8G8",
};
export default config;

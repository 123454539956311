import { Route } from "react-router-dom";
import Blogs from "../components/Blogs";
import withFirebase from "../hoc/withFirebase";
import withUser from "../hoc/withUser";
import React, { Component } from "react";

class BlogsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blog: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.fetchBlog();
  }
  saveData = (data, selectedBlogData) => {
    console.log(data, "saving");
    //	docRef.doc(userRef.id).set(
    if (selectedBlogData) {
      this.props.dbWeb
        .collection("blogs")
        .doc(selectedBlogData.id)
        .set(data)
        .then(() => {
          console.log("addedd");
        })
        .catch((error) => {
          console.log("errorMsg = ", error.message);
        });
    } else {
      this.props.dbWeb
        .collection("blogs")
        .add(data)
        .then(() => {
          console.log("added");
        })
        .catch((error) => {
          console.log("errorMsg = ", error.message);
        });
    }
  };

  fetchBlog = () => {
    console.log(
      this.props.dbWeb,
      this.props.db,
      this.props.dbWeb.collection("blogs"),
      this.props.db.collection("blogs"),
      "  this.props.dbWeb  this.props.dbWeb"
    );
    this.props.dbWeb
      .collection("blogs")

      // .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        const blog = [];
        querySnapshot.forEach((doc) => {
          const singleblog = doc.data();
          singleblog.id = doc.id;
          blog.push(singleblog);
        });
        this.setState({ blog: blog });
      });
  };
  render() {
    return (
      <Route
        exact
        path={["/blogs", "/blogs/:blogType/:id", "/blogs/new"]}
        render={(props) => (
          <Blogs
            fetchBlog={this.fetchBlog}
            saveData={this.saveData}
            {...this.props}
            {...this.state}
          />
        )}
      />
    );
  }
}

export default withFirebase(withUser(BlogsContainer));

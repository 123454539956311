import { Route } from "react-router-dom";
import FaqDetails from "../components/FaqDetails";
import withFirebase from "../hoc/withFirebase";
import withUser from "../hoc/withUser";
import React, { Component } from "react";

class WebsiteFaqContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      websiteFaq: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.fetchWebsiteFaq();
  }

  fetchWebsiteFaq = (createdAt) => {
    this.props.dbWeb
      .collection("askUs")
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        const websiteFaq = [];
        querySnapshot.forEach((doc) => {
          const singleFaqDetail = doc.data();
          singleFaqDetail.id = doc.id;
          websiteFaq.push(singleFaqDetail);
        });
        this.setState({ websiteFaq: websiteFaq });
      });
  };
  render() {
    return (
      <Route
        exact
        path={["/faq's", "/faq's/:faq'sType/:id", "/faq's/new"]}
        render={(props) => (
          <FaqDetails
            fetchWebsiteFaq={this.fetchWebsiteFaq}
            {...this.props}
            {...this.state}
          />
        )}
      />
    );
  }
}

export default withFirebase(withUser(WebsiteFaqContainer));

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ChipInput from "material-ui-chip-input";

const styles = (theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "33%",
    height: theme.spacing(17),
    borderRadius: "10px",
  },
  chipCss: {
    margin: "7px",
    backgroundColor: "#404040",
    color: "white",
    borderRadius: "5px",
    width: "100%",
  },
  spanStyle: {
    fontFamily: "Poppins",
    color: "grey",
  },
  selectClass: {
    marginLeft: "2%",
  },
});
const CssChipInput = withStyles({
  root: {
    "&.MuiFormControl-root": {
      width: "100%",
      minWidth: "100%",
    },
    "&.MuiChip-deleteIcon": {
      color: "#DCDCDC",
    },
    // "& .MuiInputLabel-outlined": {
    //   transform: "translate(18.5px, 18.5px) scale(1)",
    //   color: "grey",
    // },
  },
})(ChipInput);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography style={{ margin: "0 -24px" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
    height: "100%",
    width: "100%",
  },
}));

class DialogSubject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "all",
      subjectsArr: this.props.selectedBlogData
        ? this.props.selectedBlogData.tags
        : [],
      openErrorSnackBar: false,
    };
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleAddSubject = (chip) => {
    let chipArr = this.state.subjectsArr;
    chipArr.push(chip);
    this.props.blogTag(chipArr);
    this.setState({ subjectsArr: chipArr });
  };
  handleErrorCloseSnackbar = () => {
    this.setState({ openErrorSnackBar: false });
  };
  handleDeleteChip = (chip, index) => {
    let chipArr = this.state.subjectsArr;
    chipArr.splice(index, 1);
    this.setState({ subjectsArr: chipArr });
  };
  render() {
    const { subjectsMasterData } = this.props;
    const { subjectAdded } = this.state;
    return (
      <div style={{ margin: "0", width: "100%" }}>
        <CssChipInput
          // InputLabelProps={{
          //   shrink: false,
          // }}
          label={"Add Tag"}
          value={this.state.subjectsArr}
          onChange={() => {
            console.log("object");
          }}
          onAdd={(chip) => this.handleAddSubject(chip)}
          onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
          autoFocus={true}
          style={{
            marginTop: "0%",
          }}
          allowDuplicates={false}
          blurBehavior="clear"
          clearInputValueOnChange={false}
          delayBeforeAdd={false}
          disableUnderline={true}
          variant="outlined"
        />
      </div>
    );
  }
}

export default withRouter(DialogSubject);

import React from 'react';
export const ColorSingleclass = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g transform="translate(0 0)">
        <defs></defs>
        <circle
          style={{
            fill:props.color
                ? props.color
                : "",
            opacity: "0.87",
          }}
          cx="5"
          cy="5"
          r="5"
          transform="translate(0 6)"
        />
      </g>
    </svg>
  );
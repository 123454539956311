import React, { Component } from "react";
import {
  Paper,
  Tooltip,
  Avatar,
  Divider,
  Tabs,
  Tab,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Layout from "../layouts/Layout";
import CloseIcon from "@material-ui/icons/Close";
export class WebsiteContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactUsWebsite: "",
      selectedcontact: [],
      selectedcontact: null,
      // You can also pass a Quill Delta here, when you use edit mode for any blog, you can simply pass old delta here.

      tabs: [
        {
          label: "Website Contact",
          tooltip: "Click to for WebsiteContact data",
          data: props.users,
          route: `/websiteContacts/${"contactDetail"}`,
          primaryField: "name",
          secondaryField: "email",
          avatarField: "imageURL",
        },
      ],
    };
  }

  UNSAFE_componentWillReceiveProps(next) {
    const tabData = this.state.tabs;
    if (next) {
      tabData[0].data = next.contactUsWebsite;

      this.setState({ tabs: tabData, contactUsWebsite: next.contactUsWebsite });
    }
    console.log(next.contactUsWebsite, "ddddddddddd");
  }

  listClickHandler = (data) => {
    // this.props.fetchContact(data.id);

    const selectedcontact = this.state.contactUsWebsite.filter(
      (contactUsWebsite) => contactUsWebsite.createdBy === data.id
    );
    this.setState({ selectedcontact, selectedcontact: data });
  };
  closePage = () => {
    this.setState(
      {
        selectedcontact: null,
      },
      () => {
        this.props.history.push("/websiteContacts");
      }
    );
  };
  render() {
    const { tabs, contactUsWebsite, selectedcontact } = this.state;
    return (
      <Layout
        setTab={(tab) => {
          //this.setState({ currentTab: tab });
        }}
        search={{
          //data: currentTab === 0 ? tabs[0].data : tabs[1].data, // Optional, In case if you not providing this, tabs data will be placed.
          hintText: "Search contacts", // Optional
          labelField: "name",
        }}
        tabs={tabs}
        listClickHandler={this.listClickHandler}
      >
        {selectedcontact !== null ? (
          <>
            <Paper
              style={{
                height: window.innerHeight - 90,
                margin: "20 0 20 0",
                width: "fill-available",
                overflowY: "scroll",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginBottom: "20px",
                }}
              >
                {" "}
                <div
                  style={{
                    height: 100,
                    width: "100%",
                    border: `2px solid #1E32FA`,
                    marginBottom: 10,
                    borderRadius: "12px",
                    textAlign: "center",
                  }}
                >
                  <CloseIcon
                    style={{
                      display: "flex",
                      // top: "15px",
                      //right: "30px",
                      position: "absolute",
                    }}
                    variant="extended"
                    size="small"
                    aria-label="close"
                    onClick={(e) => {
                      e.preventDefault();
                      this.closePage();
                    }}
                  />
                  <h2>Website Contact Details</h2>
                </div>
              </div>

              <Table size="small" style={{ marginTop: 10 }}>
                <TableHead>
                  {/* <TableRow>
                  <TableCell align="left">
                    <h2>Section</h2>
                  </TableCell>
                </TableRow> */}
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <div style={{ display: "flex", margin: "10px" }}>
                        <span
                          style={{
                            fontSize: "1rem",
                            fontWeight: "bold",
                            flex: " 1 1 0%",
                          }}
                        >
                          Name
                        </span>
                        <span
                          style={{
                            flex: "1 1 0%",
                            fontSize: "1rem",
                            whiteSpace: " pre-line",
                          }}
                        >
                          {selectedcontact?.name && selectedcontact.name}
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <div style={{ display: "flex", margin: "10px" }}>
                        <span
                          style={{
                            fontSize: "1rem",
                            fontWeight: "bold",
                            flex: " 1 1 0%",
                          }}
                        >
                          Email{" "}
                        </span>
                        <span
                          style={{
                            flex: "1 1 0%",
                            fontSize: "1rem",
                            whiteSpace: " pre-line",
                          }}
                        >
                          {selectedcontact.email}
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <div style={{ display: "flex", margin: "10px" }}>
                        <span
                          style={{
                            fontSize: "1rem",
                            fontWeight: "bold",
                            flex: " 1 1 0%",
                          }}
                        >
                          PhoneNumber{" "}
                        </span>
                        <span
                          style={{
                            flex: "1 1 0%",
                            fontSize: "1rem",
                            whiteSpace: " pre-line",
                          }}
                        >
                          {selectedcontact.phoneNumber}{" "}
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <div style={{ display: "flex", margin: "10px" }}>
                        <span
                          style={{
                            fontSize: "1rem",
                            fontWeight: "bold",
                            flex: " 1 1 0%",
                          }}
                        >
                          Message or Question{" "}
                        </span>
                        <span
                          style={{
                            flex: "1 1 0%",
                            fontSize: "1rem",
                            whiteSpace: " pre-line",
                          }}
                        >
                          {selectedcontact.message}{" "}
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </>
        ) : (
          <Paper
            style={{
              height: 500,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3 style={{ padding: 0 }}>You Have No Data To Show</h3>
          </Paper>
        )}
      </Layout>
    );
  }
}

export default WebsiteContactDetails;

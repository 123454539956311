import React, { Component } from "react";
import { Paper, Tooltip, Avatar, Divider, Tabs, Tab } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import readingTime from "reading-time";
import Moment from "moment";
import firebase from "firebase/app";

export class BlogPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      institutes: [],
      title: "",
      blogCategory: [],
      blogTag: [],
      blog: "",
      author: "",
      description: "",
      totalReadTime: {},
      preview: false,
    };
  }

  render() {
    const {
      title,
      description,
      blogTag,
      author,
      blogCategory,
      BlogImageData,
      totalReadTime,
      date,
      slug,
    } = this.props;
    console.log(
      title,
      description,
      blogTag,
      author,
      blogCategory,
      BlogImageData,
      totalReadTime,
      date,
      slug,
      "title, description, blogTag, author, blogCategory"
    );
    console.log(BlogImageData, "anuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu");
    return (
      <div>
        {" "}
        <Paper
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            overflowY: "auto",
            flexDirection: "column",
          }}
        >
          <div style={{ height: "auto", width: "100%", display: "flex" }}>
            <div style={{ width: "100%", padding: "0 25px" }}>
              <div style={{ margin: "20px 0px 40px 0" }}>
                <span
                  style={{
                    background: " transparent  0% 0% no-repeat padding-box",
                    opacity: "1",
                    fontSize: "1.5rem",
                    font: " normal normal normal  Poppins",
                    textTransform: "capitalize",
                  }}
                >
                  Blog Preview
                </span>
              </div>
              <div style={{ fontFamily: "Poppins" }}>
                <h2 style={{ margin: "0 25px" }}>{title}</h2>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    margin: "15px 25px 10px 25px",
                  }}
                >
                  <div
                    style={{
                      width: "60%",
                      margin: "12px 0 0 0",
                      letterSpacing: "1.5px",
                    }}
                  >
                    {`${Moment(this.props.date).format("YYYY-MM-DD")}`},
                    {this.props.totalReadTime.text}
                  </div>
                  <div style={{ width: "40%", display: "flex" }}>
                    <Avatar
                      style={{
                        height: "35px",
                        width: "35px",
                        backgroundColor: "lightgrey",
                      }}
                    ></Avatar>
                    <span
                      style={{
                        margin: "7px 10px 0 10px",
                        textTransform: "capitalize",
                        font: " normal normal medium 18px/32px Poppins",
                        letterSpacing: " 0px",
                        color: " #A5A5A5",
                      }}
                    >
                      Author:{" "}
                      <span style={{ color: "black", letterSpacing: "1.5px" }}>
                        {author}
                      </span>
                    </span>
                  </div>
                </div>
                {console.log(author, "jjjjjj")}
                {BlogImageData?.map((BlogImageData) => (
                  <div
                    style={{ maxWidth: "900px", margin: "15px 25px 0 25px" }}
                  >
                    <img
                      style={{
                        //height: "40%",
                        width: "100%",
                        borderRadius: "20px",
                      }}
                      src={BlogImageData.insert.image}
                    />
                  </div>
                ))}
                {console.log(description, "hhhh")}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {" "}
                  <div style={{ margin: "0 25px" }}>
                    {description?.map((description) => (
                      <p
                        style={{
                          width: "fit-content",
                          margin: "0 0px 0 0",
                          fontWeight:
                            description.attributes?.bold === true ? "bold" : "",
                          fontStyle:
                            description.attributes?.italic === true
                              ? "italic"
                              : "",
                          textDecoration:
                            description.attributes?.underline === true
                              ? "underline"
                              : "",
                          fontFamily: description.attributes?.font
                            ? description.attributes.font
                            : "",
                        }}
                      >
                        {description.attributes?.link ? (
                          <a href={description.attributes?.link}>
                            {" "}
                            {description.attributes?.link}
                          </a>
                        ) : (
                          description.insert
                        )}
                      </p>
                    ))}{" "}
                  </div>
                </div>
              </div>
              <div
                style={{
                  padding: "0 25px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={() => this.props.setPrevie()}
                  style={{
                    textAlign: " right",
                    marginRight: "10px",
                    font: " normal normal medium 20px/30px Poppins",
                    letterSpacing: " 0px",
                    color: " #1E32FA",
                    opacity: "1",
                    fontSize: "0.8rem",
                  }}
                >
                  Go Back
                </Button>
                {console.log(this.props.date, "this.props.date")}
                <Button
                  onClick={() => {
                    console.log(title, "1");
                    if (title) {
                      console.log("2", BlogImageData, description);
                      this.props.saveData(
                        {
                          readingTime: this.props.totalReadTime.text,
                          title: title,
                          description: description,
                          tags: blogTag,
                          date: firebase.firestore.FieldValue.serverTimestamp(),
                          imageUrls: BlogImageData,
                          authorName: author,
                          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                          updatedOn: "ss",
                          categories: blogCategory, //[("", "")],
                          createdBy: "ss",
                          slug: slug,
                          dynamicLink: "ss",
                          likesCount: "num",
                          status: "publish",
                        },
                        this.props.selectedBlogData
                      );
                      this.props.setPrevie();
                    }
                  }}
                  style={{
                    margin: "30px 0 30px 10px",
                    padding: "10px 50px",
                    fontSize: "0.7rem",
                    background: " #1E32FA 0% 0% no-repeat padding-box",
                    boxShadow: "0px 3px 6px #00000033",
                    borderRadius: " 11px",
                    opacity: " 1",
                  }}
                  variant="contained"
                  color="primary"
                >
                  Publish
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

export default BlogPreview;

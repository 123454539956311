// import React from "react";
// import withFirebase from "./withFirebase";

// const access = {
//   dashboard:false,
//   institutes:false,
//   settings:false
// };

// const AuthUserContext = React.createContext(null);

// const withAuthentication = Component => {
//   class WithAuthentication extends React.Component {
//     constructor(props) {
//       super(props);
//       const authUser = JSON.parse(localStorage.getItem("user"));
//       this.state = { authUser };
//     }

//     componentDidMount() {
//       this.addUsers();
//     }

//     purgeUser = (authUser, access) => {
//       const user = { ...authUser.providerData[0], uid: authUser.uid, access };
//       this.setState({ authUser: user });
//       localStorage.setItem("user", JSON.stringify(user));
//     };

//     addUsers = () => {
//       this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
//         if (authUser) {
//           this.setState({ authUser });
//           this.props.db
//             .collection("users")
//             .doc(authUser.uid)
//             .get()
//             .then(user => {
//               if (!user.exists) {
//                 this.props.db
//                   .collection("users")
//                   .doc(authUser.uid)
//                   .set({
//                     email: authUser.email,
//                     displayName: authUser.displayName,
//                     imageURL: authUser.photoURL,
//                     access
//                   })
//                   .then(() => console.log("Success"))
//                   .catch(console.error);
//                 this.purgeUser(authUser, access);
//               } else this.purgeUser(authUser, user.data().access);
//             });
//         } else this.setState({ authUser: null });
//       });
//     };

//     render() {
//       return (
//         <AuthUserContext.Provider value={this.state.authUser}>
//           <Component {...this.props} />
//         </AuthUserContext.Provider>
//       );
//     }
//   }
//   return withFirebase(WithAuthentication);
// };

// export { AuthUserContext };
// export default withAuthentication;
import React from "react";
import withFirebase from "./withFirebase";

const access = {
  institutes: false,
  website: false,
  subjectmaster: false,
  settings: false,
  allteacherslist: false,
};

const AuthUserContext = React.createContext(null);

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      const authUser = JSON.parse(localStorage.getItem("user"));
      this.state = { authUser };
    }

    componentWillMount() {
      this.addUsers();
    }

    purgeUser = (authUser, access, status) => {
      const user = { ...authUser.providerData[0], uid: authUser.uid };
      user.access = access;
      user.status = status;
      this.setState({ authUser: user });
      localStorage.setItem("user", JSON.stringify(user));
    };

    addUsers = () => {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        (authUser) => {
          if (authUser) {
            this.setState({ authUser });
            this.props.db
              .collection("users")
              .doc(authUser.uid)
              .get()
              .then((user) => {
                if (!user.exists) {
                  this.props.db
                    .collection("users")
                    .doc(authUser.uid)
                    .set({
                      email: authUser.email,
                      displayName: authUser.displayName,
                      imageURL: authUser.photoURL,
                      access,
                      id: authUser.uid,
                      status: "pending",
                    })
                    .then(() => console.log("Success"))
                    .catch(console.error);
                  this.purgeUser(authUser, access, "pending");
                } else
                  this.purgeUser(
                    authUser,
                    user.data().access,
                    user.data().status
                  );
              });
          } else this.setState({ authUser: null });
        }
      );
    };

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }
  return withFirebase(WithAuthentication);
};

export { AuthUserContext };
export default withAuthentication;

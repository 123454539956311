import React from "react";
import { Snackbar, SnackbarContent } from "@material-ui/core";

export default function SnackbarComponent(props) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: props.error ? "right" : "center",
      }}
      open={true}
      autoHideDuration={2000}
      onClose={() => props.handleCloseSnackbar()}
    >
      <SnackbarContent
        style={{
          backgroundColor: props.adminRequestPage ? "grey" : "#f70d1a",
        }}
        message={
          <span id="message-id" style={{ color: "white" }}>
            {props.message ? props.message : "update successfully"}
          </span>
        }
      />
    </Snackbar>
  );
}

import { Paper } from "@material-ui/core";
import React, { Component } from "react";
import Layout from "../layouts/Layout";
import data from "./data/AllAccessKey";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  TextField,
  Button,
  DialogActions,
  DialogContent,
  Slide,
  Snackbar,
  SnackbarContent,
  IconButton,
  Checkbox,
  Tooltip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import InviteIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";
import ReactDOM from "react-dom";
import Moment from "moment";
import SnackbarComponent from "./SnackbarComponent";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ChipInput from "material-ui-chip-input";
const styles = (theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "33%",
    height: theme.spacing(17),
    borderRadius: "10px",
  },
  chipCss: {
    margin: "7px",
    backgroundColor: "#404040",
    color: "white",
    borderRadius: "5px",
    width: "100%",
  },
  spanStyle: {
    fontFamily: "Poppins",
    color: "darkGrey",
  },
  selectClass: {
    marginLeft: "12%",
  },
});
const CssChipInput = withStyles({
  root: {
    "&.MuiFormControl-root": {
      width: "100%",
      minWidth: "100%",
    },
  },
})(ChipInput);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography style={{ margin: "0 -24px" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const CSSCheckbox = withStyles((theme) => ({
  root: {
    "& .MuiSvgIcon-root": {
      height: "20px",
      width: "20px",
    },
  },
}))(Checkbox);
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  Snackbar: {
    backgroundColor: "green",
  },
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
    height: "100%",
    width: "100%",
  },
}));
export class SubjectMaster extends Component {
  constructor(props) {
    super(props);
    const allAccessLists = data.allAccessLists;
    const access = data.access;
    this.state = {
      selectedSubject: "",
      institutes: "",
      openSnackbarForUpdate: false,
      openInviteDialog: false,
      openSnackbarForUpdate: false,
      iniviteHasDone: false,
      value: "all",
      subjectsArr: [],
      openErrorSnackBar: false,
      selectedSubject: null,
      allAccessLists,
      subjectAdded: false,
      access,
      tabs: [
        {
          label: "Approved",
          tooltip: "Click here for approved subject",
          data: props.users,
          route: `/subjects/${"approved"}`,
          primaryField: "name",
          secondaryField: "status",
          avatarField: "imageURL",
        },
        {
          label: "Pending",
          tooltip: "Click here for pending subject",
          data: props.users,
          route: `/subjects/${"pending"}`,
          primaryField: "name",
          secondaryField: "status",
          avatarField: "imageURL",
        },
      ],
      subjects: null,
      selectedSubject: null,
      addSubjects: false,
    };
  }
  componentWillMount() {
    this.fetchBlog(this.props);
  }
  componentWillReceiveProps(next) {
    this.fetchBlog(next);
  }

  fetchBlog = (data) => {
    if (data.subjects) {
      console.log(data.subjects, "data.subjectsdata.subjects");
      let pendingList = data.subjects.filter(
        (subjects) => subjects.status === "pending"
      );
      let approvedList = data.subjects.filter(
        (subjects) => subjects.status === "approved"
      );
      console.log(
        approvedList,
        pendingList,
        "pendingListpendingListpendingListpendingList"
      );
      const tabData = this.state.tabs;
      tabData[0].data = approvedList;
      tabData[1].data = pendingList;

      this.setState({
        tabs: tabData,
        subjects: data.subjects,
      });
    }
    if (data.match.params.hasOwnProperty("tab")) {
      let tab = data.match.params["tab"];
      if (tab === "approved" && data.match.params.hasOwnProperty("id")) {
        console.log(this.state.tabs[1].data, "kkkkkk");
        this.setState(
          {
            user: this.state.tabs[1].data[data.match.params["id"]],
          },
          () => this.setInitialStates(this.state.subjects)
        );
      } else if (tab === "pending" && data.match.params.hasOwnProperty("id")) {
        console.log(
          this.state.tabs[0].data[data.match.params["id"]],
          "pending"
        );
        this.setState(
          {
            user: this.state.tabs[0].data[data.match.params["id"]],
          },
          () => this.setInitialStates(this.state.subjects)
        );
      } else if (tab === "new") {
        this.setState({ subjects: null });
      }
    } else this.setState({ subjects: null });
  };
  listClickHandler = (data) => {
    if (data.instituteId) {
      console.log(data.instituteId, "object");
      this.props.fetchInstituteName(data.instituteId);
    }
    console.log(data, "data");
    this.setState({ selectedSubject: "", addSubjects: false }, () => {
      this.setState({ selectedSubject: data }, () => {
        console.log(this.state.selectedSubject, "anushreeeeee");
      });
    });
    // this.props.fetchBlog(data.id);
  };
  handleCloseSnackBar = () => {
    this.setState({ iniviteHasDone: false, openSnackbarForUpdate: false });
  };
  closePage = () => {
    this.setState(
      {
        selectedSubject: null,
        subjectAdded: false,
      },
      () => {
        this.props.history.push("/subjects");
      }
    );
  };
  setInitialStates = (whichProp) => {
    if (whichProp) {
      this.setState({
        access: whichProp.access,
        status: whichProp.status,
      });
    }
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleAddSubject = (chip) => {
    let chipArr = this.state.subjectsArr;
    chipArr.push(chip);
    this.setState({ subjectsArr: chipArr });
  };
  handleErrorCloseSnackbar = () => {
    this.setState({ openErrorSnackBar: false });
  };
  handleDeleteChip = (chip, index) => {
    let chipArr = this.state.subjectsArr;
    chipArr.splice(index, 1);
    this.setState({ subjectsArr: chipArr });
  };
  // closePage = (view) => {
  //   this.setState({ selectedSubject: null });
  // };
  render() {
    const {
      tabs,
      subjects,
      openSnackbarForUpdate,
      selectedSubject,
      addSubjects,
      instituteName,
      subjectAdded,
      status,
      name,
    } = this.state;
    const { selectedSubjectInstitutedata } = this.props;
    const classes = useStyles;
    return (
      <Layout
        tabs={tabs}
        search={{
          data: this.usersData,
          hintText: "Search Users",
          labelField: "name",
        }}
        fabClickHandler={(e) => {
          this.props.history.push("/subjects");
          this.setState({
            addSubjefcts: true,
            selectedSubject: "",
          });
        }}
        listClickHandler={this.listClickHandler}
      >
        {console.log(tabs, "tabstabstabstabstabstabstabstabs")}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "fill-available",
            height: window.innerHeight - 90,
          }}
        >
          {selectedSubject !== null ? (
            <>
              <Paper
                style={{ width: "100%", height: "100%", position: "relative" }}
              >
                {selectedSubject ? (
                  <Paper
                    style={{
                      height: window.innerHeight - 90,
                      margin: "20 0 20 0",
                      width: "fill-available",
                      overflowY: "scroll",
                    }}
                  >
                    <Tooltip
                      title="Close"
                      aria-label="add"
                      placement="top"
                      disableFocusListener
                      disableTouchListener
                      arrow
                    >
                      <CloseIcon
                        style={{
                          display: "flex",
                          top: "10px",
                          left: "10px",
                          position: "absolute",
                        }}
                        variant="extended"
                        size="small"
                        aria-label="close"
                        onClick={(e) => {
                          e.preventDefault();
                          this.closePage();
                        }}
                      />
                    </Tooltip>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        style={{
                          height: 100,
                          width: "100%",
                          border: `2px solid #1E32FA`,
                          marginBottom: 10,
                          borderRadius: "12px",
                          textAlign: "center",
                        }}
                      >
                        <h2>Subject Master Data</h2>
                      </div>
                    </div>

                    <Table size="small" style={{ marginTop: 10 }}>
                      <TableHead>
                        {/* <TableRow>
                  <TableCell align="left">
                    <h2>Section</h2>
                  </TableCell>
                </TableRow> */}
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <div style={{ display: "flex", margin: "10px" }}>
                              <span
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                  flex: " 1 1 0%",
                                }}
                              >
                                InstituteName{" "}
                              </span>
                              <span
                                style={{
                                  flex: "1 1 0%",
                                  fontSize: "1rem",
                                  whiteSpace: " pre-line",
                                }}
                              >
                                {selectedSubjectInstitutedata?.name &&
                                  selectedSubjectInstitutedata.name}
                                {selectedSubject.instituteName &&
                                  selectedSubject.instituteName}
                              </span>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <div style={{ display: "flex", margin: "10px" }}>
                              <span
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                  flex: " 1 1 0%",
                                }}
                              >
                                SubjectName{" "}
                              </span>
                              <span
                                style={{
                                  flex: "1 1 0%",
                                  fontSize: "1rem",
                                  whiteSpace: " pre-line",
                                }}
                              >
                                {selectedSubject.name && selectedSubject.name}
                              </span>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <div style={{ display: "flex", margin: "10px" }}>
                              <span
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                  flex: " 1 1 0%",
                                }}
                              >
                                Date{" "}
                              </span>
                              <span
                                style={{
                                  flex: "1 1 0%",
                                  fontSize: "1rem",
                                  whiteSpace: " pre-line",
                                }}
                              >
                                {Moment(
                                  selectedSubject.createdAt.seconds * 1000
                                ).format("ll")}
                              </span>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "10px 0 0 40px",
                        flexDirection: "column",
                        textAlign: "left",
                      }}
                    >
                      {" "}
                      <span
                        style={{
                          display: "inline-block",
                          //marginLeft: "15px",

                          fontSize: "1.2rem",
                          fontWeight: "500",
                          margin: "0 0 10px 0",
                        }}
                      >
                        <span>
                          Institutename:{" "}
                          {selectedSubjectInstitutedata?.name &&
                            selectedSubjectInstitutedata.name}
                          {selectedSubject.instituteName &&
                            selectedSubject.instituteName}
                        </span>
                      </span>
                      <span
                        style={{
                          display: "inline-block",
                          fontSize: "1.2rem",

                          fontWeight: "500",
                          margin: "0 0 10px 0",
                          //marginLeft: "15px",
                        }}
                      >
                        <span>
                          Subjectname:{" "}
                          {selectedSubject.name && selectedSubject.name}
                        </span>
                      </span>
                      <span
                        style={{
                          display: "inline-block",
                          fontSize: "1.2rem",

                          fontWeight: "500",
                          margin: "0 0 10px 0",
                          //marginLeft: "15px",
                        }}
                      >
                        <span>
                          Date:{" "}
                          {Moment(
                            selectedSubject.createdAt.seconds * 1000
                          ).format("ll")}
                        </span>
                      </span>
                    </div> */}

                    <div
                      style={{
                        display: " flex",

                        padding: "50px 0 0 35px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          margin: "0 30px 0 0",
                        }}
                      >
                        <Button
                          style={{ backgroundColor: "rgb(30, 50, 250)" }}
                          onClick={() => {
                            this.props.saveData(
                              {
                                // name:  {selectedSubject.name && selectedSubject.name},
                                status: "approved",
                              },
                              selectedSubject
                            );
                          }}
                          variant="contained"
                          color="primary"
                        >
                          approved
                        </Button>
                      </div>
                      <div>
                        <Button
                          style={{ backgroundColor: "rgb(30, 50, 250)" }}
                          onClick={() => {
                            this.props.saveData(
                              {
                                // name:  {selectedSubject.name && selectedSubject.name},
                                status: "reject",
                              },
                              selectedSubject
                            );
                          }}
                          variant="contained"
                          color="primary"
                        >
                          Reject
                        </Button>
                      </div>
                    </div>

                    <Snackbar
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      open={openSnackbarForUpdate}
                      autoHideDuration={2000}
                      onClose={this.handleCloseSnackBar}
                    >
                      <SnackbarContent
                        style={{
                          backgroundColor: "lightblue",
                        }}
                        message={
                          <span id="message-id">successfully updated</span>
                        }
                        action={[
                          <IconButton
                            key="close"
                            aria-label="close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.handleCloseSnackBar}
                          >
                            <CloseIcon />
                          </IconButton>,
                        ]}
                      />
                    </Snackbar>
                  </Paper>
                ) : addSubjects ? (
                  <>
                    <Dialog
                      ref={(e) => {
                        try {
                          const target = ReactDOM.findDOMNode(e).children[2]
                            .children[0];
                          target.style.borderRadius = "20px";
                          target.style.width = "50%";
                          target.style.maxWidth = "100%";
                          target.style.height = "60%";
                          target.style.margin = "70px 20px 20px 20px";
                          target.style.padding = "20px";
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                      scroll={"paper"}
                      open={true}
                      onClose={this.handleClose}
                      aria-labelledby="alert-dialog-title"
                    >
                      <div
                        style={{
                          position: "fixed",
                          left: "48%",
                          top: "50px",
                          height: "30%",
                          width: "30%",
                        }}
                      >
                        {/* <DialogSubjectIcon /> */}
                      </div>
                      <DialogActions
                        style={{
                          display: "flex",
                          margin: "0 0 0px 0 ",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          fontSize: "1rem",
                          color: "grey",
                        }}
                      >
                        <div
                          style={{
                            width: "65%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              margin: "10px 15px",
                              fontSize: "1.0rem",
                              fontWeight: "500",
                              color: "black",
                            }}
                          >
                            {subjectAdded === true
                              ? "Subject has been added "
                              : "Create New Subject"}
                          </span>
                          <span
                            style={{
                              margin: "0px 15px",
                              fontSize: "0.8rem",
                              fontWeight: "300",
                              color: "grey",
                            }}
                          >
                            {subjectAdded === true
                              ? "Go to class tab for assigning subjects "
                              : "create new subject for class"}
                          </span>
                        </div>

                        <Tooltip title="close" arrow placement="top">
                          <CloseIcon
                            style={{
                              display: "flex",
                              top: "15px",
                              right: "20px",
                              position: "absolute",
                            }}
                            variant="extended"
                            size="small"
                            aria-label="close"
                            onClick={(e) => {
                              e.preventDefault();
                              this.closePage();
                            }}
                          />
                        </Tooltip>
                      </DialogActions>

                      <DialogContent
                        style={{
                          padding: "0px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        {subjectAdded === true ? (
                          <div
                            style={{
                              margin: "50px 23px",
                              display: "flex",
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            {/* {<CheckCircle subjectTab={true} />} */}
                            <span
                              style={{
                                color: "#17C261",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "30px",
                              }}
                            >
                              Subjects have been added to institute
                            </span>
                          </div>
                        ) : (
                          <div style={{ margin: "10px 20px", width: "100%" }}>
                            <CssChipInput
                              value={this.state.subjectsArr}
                              onAdd={(chip) => this.handleAddSubject(chip)}
                              onDelete={(chip, index) =>
                                this.handleDeleteChip(chip, index)
                              }
                              label="Add Subject"
                              autoFocus={true}
                              style={{
                                marginTop: "0%",
                              }}
                              allowDuplicates={false}
                              blurBehavior="clear"
                              clearInputValueOnChange={false}
                              delayBeforeAdd={false}
                              disableUnderline={true}
                              variant="outlined"
                            />
                          </div>
                        )}{" "}
                      </DialogContent>
                      <DialogActions
                        style={{
                          padding: "15px",
                        }}
                      >
                        {/* <Button
            onClick={() => {
              this.props.handleClose();
            }}
            style={{
              width: "20%",
              color: "blue",
              textTransform: "capitalize",
              margin: "0 15px 0 0",
              textDecoration: "underline",
            }}
          >
            Cancel
          </Button> */}

                        <Button
                          onClick={() => {
                            if (subjectAdded === true) {
                              this.closePage();
                            } else {
                              if (this.state.subjectsArr.length > 0) {
                                this.props.addData(this.state.subjectsArr);
                                this.setState({ subjectAdded: true });
                              } else {
                                this.setState({ openErrorSnackBar: true });
                              }
                            }
                          }}
                          // onClick={() => {
                          //   if (subjectAdded === true) {
                          //     this.handleClose();
                          //   } else {
                          //     // this.props.addNewSubjectFunction(
                          //     //         this.props.user.user.uid,
                          //     //
                          //     //       );
                          //     if (this.state.subjectsArr.length > 0) {
                          //       this.props.addData(
                          //         this.state.subjectsArr
                          //         // {
                          //         //   name: name,

                          //         //   status: "approved",
                          //         // }
                          //         // selectedSubject
                          //       );
                          //     }
                          //   }
                          // }}
                          variant="contained"
                          style={{
                            backgroundColor: "rgb(30, 50, 250)",
                            width: "20%",
                            borderRadius: "10px",
                            color: "white",
                            textTransform: "capitalize",
                            margin: "0 15px 0 0",
                          }}
                        >
                          Done
                        </Button>
                        {this.state.openErrorSnackBar === true ? (
                          <SnackbarComponent
                            error={true}
                            message={"Add the subject !"}
                            handleCloseSnackbar={this.handleErrorCloseSnackbar}
                          />
                        ) : (
                          ""
                        )}
                      </DialogActions>
                    </Dialog>
                  </>
                ) : (
                  "hhhh"
                )}
              </Paper>
            </>
          ) : (
            <Paper
              style={{
                height: 500,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h3 style={{ padding: 0 }}>You Have No Data To Show</h3>
            </Paper>
          )}
        </div>
      </Layout>
    );
  }
}

export default SubjectMaster;

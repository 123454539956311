import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core';
import './Design.css';

const styles = {
	container : {
		display        : 'flex',
		justifyContent : 'center',
		background     : 'white'
	}
};
export class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<MuiThemeProvider>
				<div className="Base">
					<div
						style={{
							height         : '75%',
							width          : '100%',
							display        : 'flex',
							alignItems     : 'center',
							justifyContent : 'center',
							flexDirection  : 'column'
						}}>
						<img src="netpractice.svg" className="Image" />

						<h1
							style={{
								color    : '#1E32FA',
								fontSize : '30px'
							}}>
							Net Practice
						</h1>
					</div>
					<div
						style={{
							background     : '#0c0bf9',
							color          : 'black',
							borderRadius   : '45px 45px 0px 0px',
							boxSizing      : ' border - box',
							height         : '25%',
							width          : '100%',
							display        : 'flex',
							alignItems     : 'center',
							justifyContent : 'center',
							flexDirection  : 'column'
						}}>
						<h2
							style={{
								fontSize   : '20px',
								fontWeight : 'inherit'
							}}>
							Login To Your Account
						</h2>

						<Button
							style={styles.container}
							variant="contained"
							color="black"
							onClick={(e) => {
								e.preventDefault();
								this.props.history.push('/authReloadWait');
								this.props.login(this.props);
							}}>
							Login
						</Button>
					</div>
				</div>
			</MuiThemeProvider>
		);
	}
}
Login.propTypes = {
	classes : PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(Login));

import React, { Component } from "react";
import { Route } from "react-router-dom";
import UserAndPermissions from "../components/UserAndPermissions";
import withFirebase from "../hoc/withFirebase";
import withUser from "../hoc/withUser";

class UserAndPermissionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersData: [],
    };
  }
  UNSAFE_componentWillMount() {
    this.allUsers();
  }
  allUsers = () => {
    this.props.db.collection("users").onSnapshot((obj) => {
      const users = [];
      obj.forEach((doc) => {
        if (doc.exists) {
          const user = doc.data();
          user.id = doc.id;
          users.push(user);
        }
      });
      this.setState({ usersData: users });
    });
  };
  updateUser = (userId, userDetails) => {
    return this.props.db.collection("users").doc(userId).update(userDetails);
  };
  render() {
    return (
      <Route
        exact
        path={[
          "/userandpermmision",
          "/userandpermmision/:tab/:id",
          "/userandpermmision/new",
        ]}
        render={(props) => (
          <UserAndPermissions
            {...this.props}
            {...this.state}
            updateUser={this.updateUser}
          />
        )}
      />
    );
  }
}
export default withFirebase(withUser(UserAndPermissionsContainer));

import React from "react";

const FirebaseContext = React.createContext(null);

export const withFirebase = (Component) => (props) => (
  <FirebaseContext.Consumer>
    {(firebase) => (
      <Component
        {...props}
        firebase={firebase}
        db={firebase.db}
        dbWeb={firebase.dbWeb}
      />
    )}
  </FirebaseContext.Consumer>
);

export default FirebaseContext;

import React, { Component } from "react";
import Layout from "../layouts/Layout";
import { Paper, Tooltip, Avatar, Divider, Tabs, Tab } from "@material-ui/core";
import TextField from "./TextField";
import BlogEditor from "./BlogEditor";
import BlogEditorDescription from "./BlogEditorDescription";
import Chips from "./Chips";
import SelectBlog from "./SelectBlog";
import Button from "@material-ui/core/Button";
import PublishingDate from "./PublishingDate";
import ReadingTime from "./ReadingTime";
import BlogPreview from "./BlogPreview";
import TextField2 from "./TextField2";
import SnackbarComponent from "./SnackbarComponent";
import slugify from "slugify";
export class Blogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      blogCategory: [],
      blogTag: [],
      blog: "",
      author: "",
      description: "",
      totalReadTime: {},
      selectedContact: null,
      preview: false,
      open: false,
      addBlog: false,
      openErrorSnackBar: false,
      currentTab: 0,
      // You can also pass a Quill Delta here, when you use edit mode for any blog, you can simply pass old delta here.

      tabs: [
        {
          label: "Publish",
          tooltip: "Click to for publish data",
          data: props.users,
          route: `/blogs/${"publish"}`,
          primaryField: "title",
          secondaryField: "authorName",
          avatarField: "imageURL",
        },
        {
          label: "Draft",
          tooltip: "Click to for draft data",
          data: props.users,
          route: `/blogs/${"draft"}`,
          primaryField: "title",
          secondaryField: "authorName",
          avatarField: "imageURL",
        },
      ],
    };
  }
  handleErrorCloseSnackbar = () => {
    this.setState({ openErrorSnackBar: false });
  };
  setPrevie = () => {
    this.setState({ preview: false });
  };
  fetchBlog = (data) => {
    let publishList = data.blog.filter((blog) => blog.status === "publish");
    let draftList = data.blog.filter((blog) => blog.status === "draft");

    const tabData = this.state.tabs;
    tabData[0].data = publishList;
    tabData[1].data = draftList;
    console.log(
      data.blog.filter((blog) => blog.status === "publish"),
      publishList,
      draftList,
      data.blog,
      "hhhhhhhhhhhhhhhhhhhhhhhhhhhhh"
    );
    this.setState({
      tabs: tabData,
      blog: data.blog,
    });

    if (data.match.params.hasOwnProperty("tab")) {
      let tab = data.match.params["tab"];

      if (tab === "publish" && data.match.params.hasOwnProperty("id")) {
        console.log(this.state.tabs[1].data, "kkkkkk");
        this.setState(
          {
            user: this.state.tabs[1].data[data.match.params["id"]],
          },
          () => this.setInitialStates(this.state.blog)
        );
      } else if (tab === "draft" && data.match.params.hasOwnProperty("id")) {
        console.log(this.state.tabs[0].data[data.match.params["id"]], "draft");
        this.setState(
          {
            user: this.state.tabs[0].data[data.match.params["id"]],
          },
          () => this.setInitialStates(this.state.blog)
        );
      } else if (tab === "new") {
        this.setState({ blog: null });
      }
    } else this.setState({ blog: null });
    console.log(data.blog, "aaaaaaaaaaa");
  };

  // UNSAFE_componentWillReceiveProps(next) {
  //    const tabData = this.state.tabs;
  //   fetchBlog();
  //   if (next) {
  //     tabData[0].data = next.blog;

  //     this.setState({
  //       tabs: tabData,
  //       blog: next.blog,
  //     });
  //   }
  //   console.log(next.blog, "anu");
  // }
  // componentWillMount() {
  //   this.fetchBlog(this.props);
  // }
  componentWillReceiveProps(next) {
    this.fetchBlog(next);
    if (next.match.params.hasOwnProperty("id"))
      this.setState({
        branch: this.state.blog[next.match.params["id"]],
      });
  }
  handleChange = (event) => {
    // this.setState({ title: event.target.value });
    console.log(event.target.value);
  };
  blogCategory = (blogCategory) => {
    console.log(blogCategory, "blogCategory");
    this.setState({ blogCategory });
  };
  blogTag = (blogTag) => {
    console.log(blogTag, "blogtag");
    this.setState({ blogTag });
  };
  AddText = (title) => {
    const slug = slugify(title); //slugify(author, "_");
    console.log(slug, "titletitle");
    // const slug = slugify(title, "_");
    // console.log(slug, "titletitle");
    this.setState({ title: title, slug });
  };
  AddTextAuthor = (author) => {
    this.setState({ author });
  };
  authorName = (author) => {
    this.setState({ author });
  };
  description = (description, totalReadTime) => {
    this.setState({ description, totalReadTime });
  };

  setBlogImageData = (setBlogImageData) => {
    this.setState({ setBlogImageData });
  };
  listClickHandler = (data) => {
    console.log(data, "data");
    this.setState({ selectedBlogData: "", addBlog: false }, () => {
      this.setState({
        selectedBlogData: data,
        title: data.title,
      });
    });
    // this.props.fetchBlog(data.id);
  };

  render() {
    const {
      tabs,
      title,
      blogCategory,
      blogTag,
      author,
      description,
      setBlogImageData,
      totalReadTime,
      selectedBlogData,
      preview,
      selectedcontact,
      addBlog,
      data,
      slug,
      currentTab,
    } = this.state;
    return (
      <Layout
        setTab={(tab) => {
          this.setState({ currentTab: tab });
        }}
        search={{
          data: currentTab === 0 ? tabs[0].data : tabs[1].data, // Optional, In case if you not providing this, tabs data will be placed.
          hintText: "Search blogs", // Optional
          labelField: "title",
        }}
        tabs={tabs}
        fabClickHandler={(data) => {
          this.props.history.push("/blogs/new");
          this.setState({ selectedBlogData: false }, () =>
            this.setState({ addBlog: true, title: "", totalReadTime: "" })
          );
          console.log(addBlog, "shashwat");
        }}
        listClickHandler={this.listClickHandler}
      >
        <Paper
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            overflowY: "auto",
            flexDirection: "column",
          }}
        >
          {selectedBlogData || addBlog ? (
            <>
              {preview ? (
                <BlogPreview
                  selectedBlogData={selectedBlogData}
                  setPrevie={this.setPrevie}
                  //this.props.saveData({
                  saveData={this.props.saveData}
                  {...this.state}
                  BlogImageData={setBlogImageData}
                  //});
                />
              ) : (
                <>
                  <div
                    style={{ height: "auto", width: "100%", display: "flex" }}
                  >
                    <div style={{ width: "60%", padding: "0 25px" }}>
                      <div style={{ margin: "20px 0px" }}>
                        <span
                          style={{
                            background:
                              " transparent  0% 0% no-repeat padding-box",
                            opacity: "1",
                            fontSize: "1.5rem",
                            font: " normal normal normal  Poppins",
                            textTransform: "capitalize",
                          }}
                        >
                          Create Blog
                        </span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          font: " normal normal normal 12px/14px Poppins",
                          letterSpacing: "0px",
                          color: " #424242",
                          opacity: " 1",
                        }}
                      >
                        {" "}
                        <div style={{ margin: "10px 0 8px 0" }}>Blog Title</div>
                        <TextField
                          title={true}
                          AddText={this.AddText}
                          selectedBlogData={selectedBlogData}
                        />
                        {slug ? slug : ""}
                        {/* <Slug
                          style={{
                            width: "100%",
                            padding: "15px",
                            border: "1px solid rgba(0, 0, 0, 0.26)",
                          }}
                          value={title ? title : ""}
                          onChange={this.AddText}
                          // className="test"
                        /> */}
                        {/* <Slug
                          style={{
                            width: "100%",
                            padding: "15px",
                            border: "1px solid rgba(0, 0, 0, 0.26)",
                            borderRadius: "5px",
                            textTransform: "capitalize",
                          }}
                          title={true}
                          selectedBlogData={selectedBlogData}
                          className="test"
                        /> */}
                      </div>
                      {/* <div style={{ margin: "10px 10px 0" }}>
                        <Slug
                          style={{ width: "100%", padding: "15px" }}
                          value={title}
                          onChange={this.AddText}
                          className="test"
                        />
                      </div> */}{" "}
                      {/* <Slug
                        style={{ width: "100%", padding: "15px" }}
                        value={title}
                        onChange={this.AddText}
                        className="test"
                      /> */}
                      <div
                        style={{
                          width: "100%",
                          margin: "20px 0 0",
                          font: " normal normal normal 12px/14px Poppins",
                          letterSpacing: "0px",
                          color: " #424242",
                          opacity: " 1",
                        }}
                      >
                        <div style={{ margin: "0 0 8px 0" }}>Add Category</div>
                        <SelectBlog
                          blogCategory={this.blogCategory}
                          selectedBlogData={selectedBlogData}
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          margin: "20px 0 0",
                          font: " normal normal normal 12px/14px Poppins",
                          letterSpacing: "0px",
                          color: " #424242",
                          opacity: " 1",
                        }}
                      >
                        <div style={{ margin: "0 0 8px 0" }}>Add Tags</div>
                        <Chips
                          selectedBlogData={selectedBlogData}
                          blogTag={this.blogTag}
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          font: " normal normal normal 12px/14px Poppins",
                          letterSpacing: "0px",
                          color: " #424242",
                          opacity: " 1",
                          margin: "20px 0 0",
                        }}
                      >
                        {" "}
                        <div style={{ margin: "0 0 8px 0" }}>
                          Enter Blog Author Name
                        </div>
                        <TextField2
                          author={true}
                          selectedBlogData={selectedBlogData}
                          authorName={author}
                          AddTextAuthor={this.authorName}
                        />
                      </div>
                      <div style={{ display: "flex", width: "100%" }}>
                        <div
                          style={{
                            width: "calc(50% - 2.5px)",
                            font: "normal normal normal 12px/14px Poppins",
                            letterSpacing: "0px",
                            color: " #424242",
                            opacity: " 1",
                            margin: "20px 2.5px 0 0",
                            // height: "70px",
                          }}
                        >
                          <div style={{ margin: "0 0 8px 0" }}>
                            Publishing date
                          </div>
                          <PublishingDate style={{ width: "100%" }} />
                        </div>
                        <div
                          style={{
                            width: "calc(50% - 2.5px)",
                            font: " normal normal normal 12px/14px Poppins",
                            letterSpacing: "0px",
                            color: " #424242",
                            opacity: " 1",
                            margin: "20px 0 0 2.5px",
                            // height: "70px",
                          }}
                        >
                          <div style={{ margin: "0 0 8px 0" }}>
                            Reading Time
                          </div>
                          <ReadingTime
                            style={{ width: "100%" }}
                            totalReadTime={totalReadTime}
                          />
                        </div>
                      </div>
                      {/* <div>
              for description
              <BlogEditorDescription description={this.description} />
            </div> */}
                    </div>
                    <div
                      style={{
                        width: "calc(40% - 16px)",
                        padding: "0 10px",
                        margin: "100px 0 0 0",
                      }}
                    >
                      <div
                        style={{
                          background: " #FFFFFF 0% 0% no-repeat padding-box",
                          border: " 2px dashed #757575",
                          borderRadius: " 12px",
                          opacity: " 1",
                          height: "100%",
                        }}
                      >
                        <div style={{ margin: "3px 0", overflowY: "auto" }}>
                          {" "}
                        </div>{" "}
                        <BlogEditor
                          selectedBlogData={selectedBlogData}
                          setBlogImageData={this.setBlogImageData}
                          blogImage={setBlogImageData}
                        />
                        {setBlogImageData ? (
                          ""
                        ) : (
                          <span
                            style={{
                              fontSize: "1.1rem",
                              fontFamily: "Poppins",
                              fontWeight: "700",
                              letterSpacing: "0px",
                              color: "#35425A",
                              margin: "0px 0px 92px",
                              display: " flex",
                              justifyContent: " center",
                              textlign: " center",
                              alignItems: "center",
                              height: "calc(100% - 184px)",
                            }}
                          >
                            Choose File For Cover Image
                          </span>
                        )}
                      </div>{" "}
                      {/* <div style={{ margin: "20px", display: "flex" }}>
                <Button
                  style={{
                    textAlign: " right",
                    font: " normal normal medium 20px/30px Poppins",
                    letterSpacing: " 0px",
                    color: " #1E32FA",
                    opacity: "1",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    if (title) {
                      this.props.saveData({
                        title: title,
                        description: description,
                        tags: blogTag,
                        imageUrls: ["", ""],
                        authorName: author,
                        createdAt: "",
                        updatedOn: "",
                        categories: blogCategory, //[("", "")],
                        createdBy: "",
                        slug: "",
                        dynamicLink: "",
                        likesCount: "num",
                        status: "draft",
                      });
                    }
                  }}
                  style={{
                    margin: "30px 20px",
                    padding: "6px 50px",
                    background: " #1E32FA 0% 0% no-repeat padding-box",
                    boxShadow: "0px 3px 6px #00000033",
                    borderRadius: " 11px",
                    opacity: " 1",
                  }}
                  variant="contained"
                  color="primary"
                >
                  save
                </Button>
              </div> */}
                    </div>
                  </div>{" "}
                  <div
                    style={{
                      width: "100%",
                      padding: "0 25px",
                      font: " normal normal normal 20px Poppins",
                      letterSpacing: "0px",
                      color: " #424242",
                      margin: "25px 0 0 0",
                    }}
                  >
                    <div style={{ margin: "0 0 5px 0" }}>Blog Content</div>
                    <BlogEditorDescription
                      selectedBlogData={selectedBlogData}
                      description={this.description}
                      blogDescription={description}
                    />
                  </div>
                  <div
                    style={{
                      padding: "0 25px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {/* <Button
                      onClick={this.handleClose}
                      style={{
                        textAlign: " right",
                        font: " normal normal medium 20px/30px Poppins",
                        letterSpacing: " 0px",
                        color: " #1E32FA",
                        opacity: "1",
                        fontSize: "0.7rem",
                      }}
                    >
                      Cancel
                    </Button> */}

                    <Button
                      onClick={() => {
                        if (
                          title !== undefined &&
                          description !== undefined &&
                          blogTag !== undefined &&
                          totalReadTime !== undefined &&
                          setBlogImageData !== undefined &&
                          author !== undefined &&
                          blogCategory !== undefined
                        ) {
                          this.props.saveData({
                            totalReadTime: totalReadTime,
                            title: title,
                            description: description,
                            tags: blogTag,
                            imageUrls: setBlogImageData,
                            authorName: author,
                            createdAt: "",
                            updatedOn: "",
                            categories: blogCategory, //[("", "")],
                            createdBy: "",
                            slug: slug,
                            dynamicLink: "",
                            likesCount: "num",
                            status: "draft",
                          });
                        } else {
                          this.setState({ openErrorSnackBar: true });
                        }
                      }}
                      style={{
                        margin: "30px 0 30px 10px",
                        padding: "10px 40px",
                        fontSize: "0.7rem",
                        background: " #1E32FA 0% 0% no-repeat padding-box",
                        boxShadow: "0px 3px 6px #00000033",
                        borderRadius: " 11px",
                        opacity: " 1",
                      }}
                      variant="contained"
                      color="primary"
                    >
                      save as draft
                    </Button>
                    <Button
                      onClick={() => {
                        if (
                          title !== undefined &&
                          description !== undefined &&
                          blogTag !== undefined &&
                          totalReadTime !== undefined &&
                          setBlogImageData !== undefined &&
                          author !== undefined &&
                          blogCategory !== undefined
                        ) {
                          this.setState({ preview: true });
                          // this.props.saveData({
                          //   title: title,
                          //   description: description,
                          //   tags: blogTag,
                          //   imageUrls: ["", ""],
                          //   authorName: author,
                          //   createdAt: "",
                          //   updatedOn: "",
                          //   categories: blogCategory, //[("", "")],
                          //   createdBy: "",
                          //   slug: "",
                          //   dynamicLink: "",
                          //   likesCount: "num",
                          //   status: "publish",
                          // });
                        } else {
                          this.setState({ openErrorSnackBar: true });
                        }
                      }}
                      style={{
                        margin: "30px 10px",
                        padding: "10px 40px",
                        fontSize: "0.7rem",
                        background: " #1E32FA 0% 0% no-repeat padding-box",
                        boxShadow: "0px 3px 6px #00000033",
                        borderRadius: " 11px",
                        opacity: " 1",
                      }}
                      variant="contained"
                      color="primary"
                    >
                      save & preview
                    </Button>
                  </div>
                </>
              )}
            </>
          ) : (
            <Paper
              style={{
                height: 500,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h3 style={{ padding: 0 }}>You Have No Data To Show</h3>
            </Paper>
          )}
        </Paper>
        {this.state.openErrorSnackBar === true ? (
          <SnackbarComponent
            error={true}
            message={"Add all fields!"}
            handleCloseSnackbar={this.handleErrorCloseSnackbar}
          />
        ) : (
          ""
        )}
      </Layout>
    );
  }
}

export default Blogs;

import React, { Component } from "react";
import Layout from "../layouts/Layout";
import { Paper, Tooltip, Avatar, Divider, Tabs, Tab } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import ClassTable from "./ClassTable";
import SelectField from "./SelectField";
import StudentsTable from "./StudentsTable";
import FilesTable from "./FilesTable";
import ChatTable from "./ChatTable";
import SessionsTable from "./SessionsTable";
import TeachersTable from "./TeachersTablee";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  TextField,
  Button,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import InviteIcon from "@material-ui/icons/Send";

const CssTabs = withStyles({
  root: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-around",
      width: "100%",
    },
  },
})(Tabs);
function TabContainer(props) {
  return (
    <Typography component="div" style={{ margin: "0 20px 0 20px " }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  root: {
    boxShadow: 0,
    width: "100%",
    // height: "calc(100vh - 174px)"
  },
});
class Institutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      institutes: [],
      teachers: "",
      classtable: [],
      subjectTeachers: [],
      subjectMesseges: [],
      filesdata: [],
      subjects: [],
      students: "",
      value: 0,
      openInviteDialog: false,
      institutedetails: false,
      singlePurchase: null,
      selectedInstitute: null,

      tabs: [
        {
          label: "institutes",
          tooltip: "Click to approve intitutes",
          data: [],
          route: "/institutes/user",
          avatarField: "logo",
          primaryField: "name",
          secondaryField: "type",
        },
      ],
    };
  }

  componentDidMount() {
    // this.setInitialValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(next) {
    const tabData = this.state.tabs;
    if (next) {
      tabData[0].data = next.institutes;

      this.setState({
        tabs: tabData,
        institutes: next.institutes,
        teachers: next.teachers,
        classtable: next.classdata,
        students: next.students,
        subjectTeachers: next.subjectTeachers,
        filesdata: next.filesdata,
        sessionsdata: next.sessionsdata,
        subjects: next.subjects,
        subjectMesseges: next.subjectMesseges,
      });
    }
    console.log(next.institutes, "kcuffffffff");
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };
  listClickHandler = (data) => {
    this.props.fetchInstitutesClasses(data.id);

    const selectedInstituteTeachers = this.state.teachers.filter(
      (teachers) => teachers.instituteId === data.id
    );

    this.setState({ selectedInstituteTeachers, selectedInstitute: data });
  };
  closePage = () => {
    this.setState({
      selectedInstitute: null,
      teachers: [],
      classtable: [],
      students: [],
      subjectTeachers: [],
      filesdata: [],
      sessionsdata: [],
      subjects: [],
      subjectMesseges: [],
    });
  };
  setinstitutedetails = () => {
    this.setState({ institutedetails: true });
  };
  handleCloseDialog = () => {
    this.setState({ openInviteDialog: false });
  };
  render() {
    const { classes } = this.props;
    const { value } = this.state;

    const {
      tabs,
      institutes,
      classtable,
      subjectTeachers,
      teachers,
      selectedInstituteTeachers,
      openInviteDialog,
      students,
      sessionsdata,
      selectedInstitute,
      filesdata,
      subjectMesseges,
      selectedInstituteClasses,
      subjects,
    } = this.state;
    return (
      <Layout
        search={{
          data: this.state.institutes, // Optional, In case if you not providing this, tabs data will be placed.
          hintText: "Search institutes", // Optional
          labelField: "name",
        }}
        tabs={tabs}
        // fabClickHandler={(e) => {
        // 	this.props.history.push('/institutes/new');
        // 	this.setState({ openInviteDialog: true });
        // }}
        listClickHandler={this.listClickHandler}
      >
        <Dialog
          open={openInviteDialog}
          keepMounted
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            style={{ textAlign: "center" }}
          >
            {"INVITE USERS"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Enter User's Email-Id
            </DialogContentText>
            <div
              style={{
                marginLeft: 4,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <TextField
                id="outlined-basic"
                label="Enter email ID"
                margin="normal"
                type="email"
                value={this.state.email}
                variant="outlined"
                style={{
                  padding: 5,
                }}
                onChange={(e) => {
                  e.preventDefault();
                  this.handleEmailChange(e);
                }}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<InviteIcon />}
                style={{
                  height: "auto",
                  width: "7vw",
                  marginLeft: "5vw",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  this.inviteButton();
                  if (this.state.email) this.handleCloseDialog();
                }}
              >
                Invite
              </Button>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {selectedInstitute !== null ? (
          <>
            <div style={{ display: "flex", justifyContent: "flex-end" }} />
            <div
              style={{
                height: 100,
                border: `2px solid #1E32FA`,
                marginBottom: 10,
                borderRadius: "12px",
                textAlign: "center",
              }}
            >
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Tooltip
                  title="Close"
                  aria-label="add"
                  placement="top"
                  disableFocusListener
                  disableTouchListener
                  arrow
                >
                  <CloseIcon
                    variant="extended"
                    size="small"
                    aria-label="close"
                    onClick={(e) => {
                      e.preventDefault();
                      this.closePage();
                    }}
                  />
                </Tooltip>
              </div>
              {selectedInstitute ? (
                <>
                  <h4
                    style={{
                      fontSize: "20px",
                      margin: "5px 0",
                      fontWeight: "300",
                    }}
                  >
                    {" "}
                    InstituteName -{selectedInstitute.name}
                  </h4>
                  <h4
                    style={{ fontWeight: "300", fontSize: "20px", margin: "0" }}
                  >
                    InstituteType-{selectedInstitute.type}
                  </h4>
                </>
              ) : (
                ""
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              />
            </div>

            <div className={classes.root}>
              <AppBar
                style={{
                  margin: "0 20px",
                  width: "calc(100% - 40px)",
                  justifyContent: "space-around",
                  overflowX: "auto",
                  boxShadow: "0 0.05rem 0.05rem 0.05rem #e0e0e0",
                  background: "primary",
                }}
                position="static"
                color="default"
              >
                <CssTabs
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                  value={value}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="black"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab style={{ minWidth: "0" }} label="Classes" />
                  <Tab style={{ minWidth: "0" }} label="Teachers" />
                  <Tab style={{ minWidth: "0" }} label="Students" />
                  <Tab style={{ minWidth: "0" }} label="Chats" />
                  <Tab style={{ minWidth: "0" }} label="Files" />
                  <Tab style={{ minWidth: "0" }} label="Sessions" />
                </CssTabs>
              </AppBar>
              {value === 0 && (
                <TabContainer>
                  <ClassTable
                    subjectTeachers={subjectTeachers}
                    fetchClassTeacher={this.props.fetchClassTeacher}
                    selectedInstituteClasses={classtable}
                  />
                </TabContainer>
              )}
              {value === 1 && (
                <TabContainer>
                  <TeachersTable
                    selectedInstituteTeachers={selectedInstituteTeachers}
                  />
                </TabContainer>
              )}
              {value === 2 && (
                <TabContainer>
                  <SelectField
                    students={true}
                    fetchInstitutesStudents={this.props.fetchInstitutesStudents}
                    selectedInstituteClasses={classtable}
                  />
                  <StudentsTable students={students} />
                </TabContainer>
              )}
              {value === 3 && (
                <TabContainer>
                  <SelectField
                    chat={true}
                    fetchClassSubjects={this.props.fetchClassSubjects}
                    selectedInstituteClasses={classtable}
                  />

                  <ChatTable
                    user={this.props.user}
                    isMobile={false}
                    groupMesseges={this.props.groupMesseges}
                    enterClassAnnouncementsChat={
                      this.props.enterClassAnnouncementsChat
                    }
                    enterSubjectChat={this.props.enterSubjectChat}
                    subjectChatGroups={subjects}
                    classAnnouncementGroups={classtable}
                    subjectMesseges={subjectMesseges}
                  />
                </TabContainer>
              )}

              {value === 4 && (
                <TabContainer>
                  <SelectField
                    files={true}
                    fetchInstitutesFiles={this.props.fetchInstitutesFiles}
                    selectedInstituteClasses={classtable}
                  />
                  <FilesTable filesdata={filesdata} />
                </TabContainer>
              )}

              {value === 5 && (
                <TabContainer>
                  <SelectField
                    sessions={true}
                    fetchInstitutesSessions={this.props.fetchInstitutesSessions}
                    selectedInstituteClasses={classtable}
                  />
                  <SessionsTable sessionsdata={sessionsdata} />
                </TabContainer>
              )}
            </div>
          </>
        ) : (
          <Paper
            style={{
              height: 500,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3 style={{ padding: 0 }}>You Have No Data To Show</h3>
          </Paper>
        )}
      </Layout>
    );
  }
}

Institutes.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Institutes);

import { Route } from "react-router-dom";
import Contact from "../components/Contact";
import withFirebase from "../hoc/withFirebase";
import withUser from "../hoc/withUser";
import React, { Component } from "react";

class ContactContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.fetchContact();
  }

  fetchContact = (createdBy) => {
    this.props.db
      .collection("contactUs")
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        const contact = [];
        querySnapshot.forEach((doc) => {
          const singlecontact = doc.data();
          singlecontact.id = doc.id;
          contact.push(singlecontact);
        });
        this.setState({ contact: contact });
      });
  };
  render() {
    return (
      <Route
        exact
        path={["/contact", "/contact/user/:id", "/contact/new"]}
        render={(props) => (
          <Contact
            fetchContact={this.fetchContact}
            {...this.props}
            {...this.state}
          />
        )}
      />
    );
  }
}

export default withFirebase(withUser(ContactContainer));

import { Route } from "react-router-dom";
import AllTeachersList from "../components/AllTeachersList";
import withFirebase from "../hoc/withFirebase";
import withUser from "../hoc/withUser";
import React, { Component } from "react";

class ContactContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teachers: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.fetchTeachers();
  }

  fetchTeachers = (createdBy) => {
    this.props.db
      .collection("users")
      .where("userType", "in", ["teacher", "admin"])
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        const teachers = [];
        querySnapshot.forEach((doc) => {
          const singleteachers = doc.data();
          singleteachers.id = doc.id;
          if (doc.data().profile) {
            singleteachers.userName =
              doc.data().profile.firstName + " " + doc.data().profile.lastName;
            // singleteachers.subjectName = doc.data().classSubjects[0].subjectName;
          } // singleteachers.className = doc.data().classSubjects[0].className;
          teachers.push(singleteachers);
        });
        this.setState({ teachers, isLoading: false });
      });
  };
  render() {
    return (
      <Route
        exact
        path={[
          "/allteacherslist",
          "/allteacherslist/user/:id",
          "/allteacherslist/new",
        ]}
        render={(props) => (
          <AllTeachersList
            fetchTeachers={this.fetchTeachers}
            {...this.props}
            {...this.state}
          />
        )}
      />
    );
  }
}

export default withFirebase(withUser(ContactContainer));

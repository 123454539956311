/** @format */

import React, { Component, Fragment } from "react";
import { ArrowBackIcon, SendIcon } from "./Icons/ChatIcon";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Layout from "../layouts/Layout";
import { green, primary, white, grey, darkGrey } from "../styles/Colors";
import {
  Typography,
  TextField,
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  withStyles,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import "../styles/chats.css";
const styles = (theme) => ({
  classGroupAvatar: {
    color: theme.palette.getContrastText("#1E32FA"),
    width: "40px",
    height: "aut0",
    fontSize: "1.1rem",
    backgroundColor: "#1E32FA",
  },
  topLeftIcon: {
    padding: "0 30px 0 0",
    minWidth: "auto",
  },
  classGroupName: {
    fontSize: "1.1rem",
    // fontWeight: "bold",
    color: "#35425A",
    textTransform: "capitalize",
    fontFamily: "Poppins",
  },
  classGroupTitle: {
    fontSize: "0.9rem",
    color: "grey",
    fontFamily: "Poppins",
  },
  topList: {
    padding: "0 0 0 5%",
    fontSize: "1.1rem",
    backgroundColor: "var(--background)",
  },
  topDesktopList: {
    padding: "5px 0",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    fontSize: "1.1rem",
    backgroundColor: "#F1F2FE",
  },
  messegeSenderName: {
    color: "#DB0011",
    fontSize: "1rem",
    fontFamily: "Poppins",
  },
  messege: {
    color: "black",
    fontSize: "1rem",
    fontFamily: "Poppins",
    wordBreak: "break-word", //"break-all",
  },
  messegeTime: {
    textAlign: "right",
    color: "grey",
    fontFamily: "Poppins",
    marginTop: "0",
    fontSize: "0.7rem",
    display: "flex",
    justifyContent: "flex-end",
  },
  userMessege: {
    color: "white",
    margin: "5px 0 0 0",
    fontFamily: "Poppins",
    fontSize: "1rem",
    wordBreak: "break-word", //"break-all",
  },
  chatsInformation: {
    margin: "0 13px",
  },
  userMessegeTime: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Poppins",
    justifyContent: "flex-end",
    flexDirection: "row",
    color: "#eeeeee",
    fontSize: "0.7rem",
  },
  dateOfMesege: {
    textAlign: "center",
    color: "grey",
    margin: "0 0 9px 0",
    fontSize: "1.1rem",
  },
  fontFamily: "Poppins",
  doubleTickIconContainer: {
    margin: "0 0 0 3%",
    justifyContent: "center",
  },
  textField: {
    boxShadow: "0 0.05rem 0.05rem 0.05rem #e0e0e0",
    borderRadius: "8px",
    width: "70%",
    margin: "0",
    fontFamily: "Poppins",
  }, //5.57%
  webTextField: {
    boxShadow: "0 0.05rem 0.05rem 0.05rem #e0e0e0",
    borderRadius: "8px",
    width: "calc(100% - 95px)",
    margin: "0",
    fontFamily: "Poppins",
  },
  uploadButton: {
    borderRadius: "8px",
    padding: "15px",
    width: "55px",
    fontFamily: "Poppins",
  },
});
export class SingleClassGroupChat extends Component {
  constructor(props) {
    super(props);
    this.topOfPageRef = React.createRef();
    this.value = [];
    this.state = {
      chatsdata: "",
      classGroupMesseges: [],
      text: "",
      colorsUid: [],
      userColor: [],
      allSenderUid: [],
    };
  }
  scrollToBottom = () => {
    const chat = document.getElementById("chatLists");
    chat.scrollTop = chat.scrollHeight;
  };
  clickedChatWhereNotSender = (chatInedx) => {
    if (this.state.classGroupMesseges.length > 0) {
      if (
        this.state.classGroupMesseges[this.state.classGroupMesseges.length - 1]
          .createdBy !== this.props.user.uid
      ) {
        if (
          this.state.classGroupMesseges[
            this.state.classGroupMesseges.length - 1
          ].uids[this.props.user.uid] === false
        ) {
          const classDoc = chatInedx;

          this.props.updateMessegeHasRead(classDoc, this.props.user.uid);
        }
      }
    }
  };
  UNSAFE_componentWillMount() {
    document.addEventListener("keydown", (event) => {
      if (event.keyCode === 13 && this.state.text !== "") {
        this.handleSendText();
      }
    });
  }
  UNSAFE_componentWillReceiveProps(next) {
    
    if (next) {
      this.state.dateArr = [];
      const dateArr = this.state.dateArr;
      this.setState(
        {
          chatsdata: next.classChatsData,
          classGroupMesseges: next.groupMesseges,
          text: "",
        },
        () => {
          this.scrollToBottom();
        //   this.clickedChatWhereNotSender(next.classChatsData.id);
        }
      );
      this.state.allSenderUid = [];
      const allSenderUid = this.state.allSenderUid;

      next.groupMesseges.forEach((data1) => {
        if (
          !dateArr.includes(Moment(data1.createdAt.seconds * 1000).format("ll"))
        ) {
          const uid = [];
          next.groupMesseges
            .filter(
              (p) =>
                Moment(p.createdAt.seconds * 1000).format("ll") ===
                Moment(data1.createdAt.seconds * 1000).format("ll")
            )
            .forEach((data1) => {
              uid.push(data1.createdBy);
            });
          allSenderUid.push({
            uid: uid,
            date: Moment(data1.createdAt.seconds * 1000).format("ll"),
          });

          dateArr.push(Moment(data1.createdAt.seconds * 1000).format("ll"));
          this.setState({ dateArr });
        }
      });
      this.state.colorsUid = [];
      const colors = [
        "#FFB300",
        "#17C261",
        "#DB0011",
        "#FFFFFF",
        "#5784FD",
        "#3FB2C7",
        "#A461D8",
        "#F26121",
        "#20428E",
        "#BABABA",
        "#FA5077",
        "#404040",

        // "45649A",
        // "green",
        // "7EC813",
        // "9D7FD6",
        // "D3A1C7",
        // "74A97D",
        // "yellow",
      ];
      const colorsUid = this.state.colorsUid;

      next.groupMesseges
        .filter((p) => p.createdBy !== this.props.user.uid)
        .forEach((data1, i) => {
          if (!colorsUid.includes(data1.createdBy)) {
            colorsUid.push(data1.createdBy);
            this.setState({ colorsUid });
          }
        });
      this.state.userColor = [];
      const userColor = this.state.userColor;
      colorsUid.forEach((h, i) => userColor.push({ uid: h, color: colors[i] }));
    }
  }
  handleChangeTextInput = (event) => {
    this.setState({ text: event.target.value });
  };
  handleSendText = () => {
    const students = {};
    this.props.students.forEach((studentsUid) => {
      students[studentsUid] = false;
    });
    students[
      this.props.students.filter(
        (studentsUid) => studentsUid === this.props.user.uid
      )
    ] = true;
    const data = {
      messege: this.state.text,
      senderName:
        this.props.user.profile.firstName +
        " " +
        this.props.user.profile.lastName,
      createdBy: this.props.user.uid,
      isTeacher: true,
      classId: this.state.chatsdata.id,
      uid: students,
    };

    if (this.state.text) {
      this.props.addText(data);
    }
    this.setState({ text: "" });
  };

  toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1);
    });
  };

  render() {
    const { classes, loadingGroups, isMobile } = this.props;

    const {
      chatsdata,
      classGroupMesseges,
      userColor,
      allSenderUid,
    } = this.state;
    return (
     
        <div
          className={
            isMobile === true
              ? "class-announcement-chat-screen-container"
              : "web-class-announcement-chat-screen-container"
          }
        >
          <List style={{ padding: isMobile === true ? "8px 0 0 0" : "0" }}>
            <ListItem
              className={
                isMobile === true ? classes.topList : classes.topDesktopList
              }
            >
              <ListItemIcon
                className={classes.topLeftIcon}
                onClick={() => this.props.history.push(`/chats`)}
              >
                {isMobile === true ? <ArrowBackIcon /> : ""}
              </ListItemIcon>
              <ListItemIcon className={classes.topLeftIcon}>
                <Avatar className={classes.classGroupAvatar}>
                  {/* {chatsdata.name.slice(6)} */}
                </Avatar>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography className={classes.classGroupName}>
                    {chatsdata.name}
                  </Typography>
                }
                secondary={
                  <Typography className={classes.classGroupTitle}>
                    Notice Board
                  </Typography>
                }
              />
            </ListItem>
          </List>
          <div
            id="chatLists"
            className={
              isMobile === true
                ? "chats-all-messege-container"
                : "web-chats-all-messege-container"
            }
          >
            {loadingGroups === true ? (
              <div className="circularProgress-container">
                <CircularProgress />
              </div>
            ) : (
              allSenderUid.map((data, i) => (
                <Fragment key={data}>
                  <h3 className={classes.dateOfMesege}>
                    {data.date === Moment(new Date()).format("ll")
                      ? "Today"
                      : data.date ===
                        Moment(new Date()).subtract(1, "days").format("ll")
                      ? "yesterday"
                      : data.date}
                  </h3>
                  {classGroupMesseges
                    .filter(
                      (filteringgroupMesseges) =>
                        Moment(
                          filteringgroupMesseges.createdAt.seconds * 1000
                        ).format("ll") === data.date
                    )
                    .map((msg, index) => (
                      <Fragment key={msg.id}>
                        {msg.createdBy !== this.props.user.uid ? (
                          <div
                            className="chats-single-messege-container"
                            key={msg.createdBy}
                          >
                            {(data.uid[index - 1] === undefined &&
                              allSenderUid[i - 1] === undefined) ||
                            (data.uid[index - 1] === undefined &&
                              allSenderUid[i - 1] !== undefined &&
                              allSenderUid[i - 1].uid[
                                allSenderUid[i - 1].uid.length - 1
                              ] !== msg.createdBy) ||
                            (data.uid[index - 1] !== undefined &&
                              msg.createdBy !== data.uid[index - 1]) ? (
                              <>
                                <div
                                  className={
                                    isMobile === true
                                      ? "single-messege-container-arrow-left"
                                      : "desktop-single-messege-container-arrow-left"
                                  }
                                ></div>
                                <div
                                  className={
                                    isMobile === true
                                      ? "single-messege-container-chat-box"
                                      : "desktop-single-messege-container-chat-box"
                                  }
                                >
                                  <ListItemText
                                    className={classes.chatsInformation}
                                    primary={
                                      <Typography
                                        style={{
                                          color: userColor.filter(
                                            (p) => p.uid === msg.createdBy
                                          )[0].color
                                            ? userColor.filter(
                                                (p) => p.uid === msg.createdBy
                                              )[0].color
                                            : "red",
                                          fontSize: "1rem",
                                          margin: "5px 0 0 0",
                                        }}
                                      >
                                        {msg.senderName}
                                      </Typography>
                                    }
                                    secondary={
                                      <>
                                        <Typography
                                          className={classes.messege}
                                          component={"div"}
                                        >
                                          {msg.messege}
                                        </Typography>
                                        <Typography
                                          component={"div"}
                                          className={classes.messegeTime}
                                        >
                                          {Moment(
                                            msg.createdAt.toDate()
                                          ).format("LT")}
                                        </Typography>
                                      </>
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="single-messege-container-arrow-none-left"></div>
                                <div
                                  className={
                                    isMobile === true
                                      ? "single-messege-container-chat-no-new-box"
                                      : "desktop-single-messege-container-chat-no-new-box"
                                  }
                                >
                                  {" "}
                                  <ListItemText
                                    className={classes.chatsInformation}
                                    primary={
                                      <Typography
                                        style={{
                                          color: userColor.filter(
                                            (p) => p.uid === msg.createdBy
                                          )[0].color
                                            ? userColor.filter(
                                                (p) => p.uid === msg.createdBy
                                              )[0].color
                                            : "red",
                                          fontSize: "1rem",
                                          fontFamily: "Poppins",
                                          margin: "5px 0 0 0",
                                        }}
                                      >
                                        {msg.senderName}
                                      </Typography>
                                    }
                                    secondary={
                                      <>
                                        <Typography
                                          className={classes.messege}
                                          component={"div"}
                                        >
                                          {msg.messege}
                                        </Typography>
                                        <Typography
                                          component={"div"}
                                          className={classes.messegeTime}
                                        >
                                          {Moment(
                                            msg.createdAt.toDate()
                                          ).format("LT")}
                                        </Typography>
                                      </>
                                    }
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        ) : (
                          <div className="chats-single-messege-container">
                            {(data.uid[index - 1] === undefined &&
                              allSenderUid[i - 1] === undefined) ||
                            (data.uid[index - 1] === undefined &&
                              allSenderUid[i - 1] !== undefined &&
                              allSenderUid[i - 1].uid[
                                allSenderUid[i - 1].uid.length - 1
                              ] !== msg.createdBy) ||
                            (data.uid[index - 1] !== undefined &&
                              msg.createdBy !== data.uid[index - 1]) ? (
                              <>
                                <div
                                  className={
                                    isMobile === true
                                      ? "single-messege-container-arrow-right"
                                      : "desktop-single-messege-container-arrow-right"
                                  }
                                />{" "}
                                <div
                                  className={
                                    isMobile === true
                                      ? "single-messege-container-chat-box-sender"
                                      : "desktop-single-messege-container-chat-box-sender"
                                  }
                                >
                                  <ListItemText
                                    className={classes.chatsInformation}
                                    primary={
                                      <Typography
                                        className={classes.userMessege}
                                      >
                                        {msg.messege}
                                      </Typography>
                                    }
                                    secondary={
                                      <Typography
                                        component={"span"}
                                        className={classes.userMessegeTime}
                                      >
                                        {Moment(msg.createdAt.toDate()).format(
                                          "LT"
                                        )}
                                        <span
                                          className={
                                            classes.doubleTickIconContainer
                                          }
                                        >
                                          <DoneAllIcon fontSize="small" />
                                        </span>
                                      </Typography>
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                {" "}
                                <div className="single-messege-container-arrow-none-right" />{" "}
                                <div
                                  className={
                                    isMobile === true
                                      ? "single-messege-container-chat-no-new-box-sender"
                                      : "desktop-single-messege-container-chat-no-new-box-sender"
                                  }
                                >
                                  <ListItemText
                                    className={classes.chatsInformation}
                                    primary={
                                      <Typography
                                        className={classes.userMessege}
                                      >
                                        {msg.messege}
                                      </Typography>
                                    }
                                    secondary={
                                      <Typography
                                        component={"span"}
                                        className={classes.userMessegeTime}
                                      >
                                        {Moment(msg.createdAt.toDate()).format(
                                          "LT"
                                        )}
                                        <span
                                          className={
                                            classes.doubleTickIconContainer
                                          }
                                        >
                                          <DoneAllIcon fontSize="small" />
                                        </span>
                                      </Typography>
                                    }
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </Fragment>
                    ))}
                </Fragment>
              ))
            )}
          </div>
          {this.props.user.userType === "teacher" ? (
            <div
              className={
                isMobile === true
                  ? "bottom-input-text-container"
                  : "web-bottom-input-text-container"
              }
            >
              <TextField
                autoComplete="off"
                className={
                  isMobile === true ? classes.textField : classes.webTextField
                }
                placeholder="Add Messege"
                margin="normal"
                variant="filled"
                name="Name"
                value={this.state.text || ""}
                onChange={this.handleChangeTextInput}
                InputProps={{
                  disableUnderline: true,
                  inputProps: {
                    style: {
                      borderRadius: "8px",
                      padding: "15px",
                      color: "grey",
                      textAlign: "left",
                    },
                  },
                  style: {
                    backgroundColor: "white",
                    borderRadius: "8px",
                    textAlign: "left",
                  },
                }}
              />
              <Button
                onClick={this.handleSendText}
                variant="contained"
                className={classes.uploadButton}
                color="secondary"
              >
                <SendIcon />
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
     
    );
  }
}

SingleClassGroupChat.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(SingleClassGroupChat));

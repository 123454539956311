import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Layout from "../layouts/Layout";
import { makeStyles } from "@material-ui/core/styles";
import data from "./data/AllAccessKey";
import {
  Paper,
  Dialog,
  DialogTitle,
  DialogContentText,
  TextField,
  Button,
  DialogActions,
  DialogContent,
  Slide,
  Snackbar,
  SnackbarContent,
  IconButton,
  Avatar,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import InviteIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";

const axios = require("axios").default;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  Snackbar: {
    backgroundColor: "green",
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class UserAndPermissions extends Component {
  constructor(props) {
    super(props);
    const allAccessLists = data.allAccessLists;
    const access = data.access;
    this.state = {
      allAccessLists,
      access,
      tabs: [
        {
          label: "Approved",
          tooltip: "Click to approve approved users",
          data: props.users,
          route: "/userandpermmision/approved",
          primaryField: "displayName",
          secondaryField: "email",
          avatarField: "imageURL",
        },
        {
          label: "Pending",
          tooltip: "Click to approve pending users",
          data: props.users,
          route: "/userandpermmision/pending",
          primaryField: "displayName",
          secondaryField: "email",
          avatarField: "imageURL",
        },
      ],
      openInviteDialog: false,
      email: "",
      iniviteHasDone: false,
      user: null,
      usersData: [],
      status: "pending",
      openSnackbarForUpdate: false,
    };
  }
  setInitialStates = (whichProp) => {
    if (whichProp) {
      this.setState({
        access: whichProp.access,
        status: whichProp.status,
      });
    }
  };
  fetchUser = (data) => {
    let pendingUsers = data.usersData.filter(
      (user) => user.status === "pending"
    );
    let approvedUsers = data.usersData.filter(
      (user) => user.status === "approved"
    );

    const tabData = this.state.tabs;
    tabData[0].data = approvedUsers;
    tabData[1].data = pendingUsers;
    this.setState({
      tabs: tabData,
      usersData: data.usersData,
    });

    if (data.match.params.hasOwnProperty("tab")) {
      let tab = data.match.params["tab"];

      if (tab === "pending" && data.match.params.hasOwnProperty("id")) {
        console.log(this.state.tabs[1].data);
        this.setState(
          {
            user: this.state.tabs[1].data[data.match.params["id"]],
          },
          () => this.setInitialStates(this.state.user)
        );
      } else if (tab === "approved" && data.match.params.hasOwnProperty("id")) {
        console.log(
          this.state.tabs[0].data[data.match.params["id"]],
          "aproved"
        );
        this.setState(
          {
            user: this.state.tabs[0].data[data.match.params["id"]],
          },
          () => this.setInitialStates(this.state.user)
        );
      } else if (tab === "new") {
        this.setState({ user: null });
      }
    } else this.setState({ user: null });
  };

  componentWillMount() {
    this.fetchUser(this.props);
  }
  componentWillReceiveProps(next) {
    this.fetchUser(next);
  }
  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handleCloseDialog = () => {
    this.setState({ openInviteDialog: false });
  };
  handleCloseSnackBar = () => {
    this.setState({ iniviteHasDone: false, openSnackbarForUpdate: false });
  };
  handleStatusChange = (e, index, value) => {
    this.setState({ status: e.target.value }, () =>
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          status: this.state.status,
          access: {
            institutes: false,
            settings: false,
            website: false,
            subjectmaster: false,
            allteacherslist: false,
          },
        },
      }))
    );
  };
  inviteButton = () => {
    if (this.state.email) {
      console.log(this.props);
      this.props.firebase
        .sendInvitationAdmin({
          text: this.state.email,
          fromAdmin: true,
          message: "ss",
        })
        .then((result) => {
          console.log("result", result);
        })
        .catch((error) => {
          console.log("error", error);
        });
      this.setState({ iniviteHasDone: true, email: "" });
    } else alert("Please enter an emailId");
  };
  handleChangeForCheckBox = (name) => {
    const access = this.state.access;
    access[name] = this.state.access[name] ? false : true;
    this.setState({ access });
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        access: access,
        status: this.state.status,
      },
    }));
  };

  closePage = () => {
    this.setState(
      {
        user: null,
      },
      () => {
        this.props.history.push("/userandpermmision");
      }
    );
  };
  render() {
    const {
      tabs,
      status,
      openInviteDialog,
      iniviteHasDone,
      user,
      allAccessLists,
      access,
      openSnackbarForUpdate,
    } = this.state;
    const classes = useStyles;
    return (
      <Layout
        tabs={tabs}
        search={{
          data: this.usersData,
          hintText: "Search Users",
          labelField: "displayName",
        }}
        fabClickHandler={(e) => {
          this.props.history.push("/userandpermmision");
          this.setState({ openInviteDialog: true });
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "fill-available",
            height: window.innerHeight - 90,
          }}
        >
          <Paper
            style={{ width: "100%", height: "100%", position: "relative" }}
          >
            {user ? (
              <Paper
                style={{
                  height: window.innerHeight - 90,
                  margin: "20 0 20 0",
                  width: "fill-available",
                  overflowY: "scroll",
                }}
              >
                <Tooltip
                  title="Close"
                  aria-label="add"
                  placement="top"
                  disableFocusListener
                  disableTouchListener
                  arrow
                >
                  <CloseIcon
                    style={{
                      display: "flex",
                      top: "10px",
                      left: "10px",
                      position: "absolute",
                    }}
                    variant="extended"
                    size="small"
                    aria-label="close"
                    onClick={(e) => {
                      e.preventDefault();
                      this.closePage();
                    }}
                  />
                </Tooltip>

                <h2
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: 10,
                  }}
                >
                  {user ? user.displayName : ""}
                </h2>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 20,
                    paddingTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    style={{ width: "30%" }}
                    onChange={this.handleStatusChange}
                  >
                    <MenuItem value={"pending"}>Pending</MenuItem>
                    <MenuItem value={"approved"}>Approved</MenuItem>
                  </Select>
                  {this.state.status === "approved" ? (
                    <Table size="small" style={{ marginTop: 10 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Section</TableCell>
                          <TableCell align="center">Access&nbsp;</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allAccessLists.map((singleData) => (
                          <TableRow key={singleData.label}>
                            <TableCell component="th" scope="row">
                              {singleData.label}
                            </TableCell>
                            <TableCell align="center">
                              <Checkbox
                                checked={access[singleData.name]}
                                onChange={() => {
                                  this.handleChangeForCheckBox(singleData.name);
                                }}
                                value="checkedB"
                                color="primary"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 5,
                    marginBottom: 5,
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      console.log(user, "1user");
                      this.setState({ openSnackbarForUpdate: true });
                      this.props
                        .updateUser(user.id, user)
                        .then(() => this.closePage());
                    }}
                  >
                    Save
                  </Button>
                </div>
                <Snackbar
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  open={openSnackbarForUpdate}
                  autoHideDuration={2000}
                  onClose={this.handleCloseSnackBar}
                >
                  <SnackbarContent
                    style={{
                      backgroundColor: "lightblue",
                    }}
                    message={<span id="message-id">successfully updated</span>}
                    action={[
                      <IconButton
                        key="close"
                        aria-label="close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.handleCloseSnackBar}
                      >
                        <CloseIcon />
                      </IconButton>,
                    ]}
                  />
                </Snackbar>
              </Paper>
            ) : (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar
                  style={{
                    backgroundColor: "white",
                    height: "200px",
                    width: "200px",
                    borderRadius: "0px",
                  }}
                  src="/useraccess.jpeg"
                />
                <h3 style={{ padding: 0 }}>Manage Permissions For Users</h3>
              </div>
            )}

            <Dialog
              open={openInviteDialog}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.handleCloseDialog}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle
                id="alert-dialog-slide-title"
                style={{ textAlign: "center" }}
              >
                {"INVITE USERS"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Enter User's Email-Id
                </DialogContentText>
                <div
                  style={{
                    marginLeft: 4,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    label="Enter email ID"
                    margin="normal"
                    type="email"
                    value={this.state.email}
                    variant="outlined"
                    style={{
                      padding: 5,
                    }}
                    onChange={(e) => {
                      e.preventDefault();
                      this.handleEmailChange(e);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    endIcon={<InviteIcon />}
                    style={{
                      height: "auto",
                      width: "7vw",
                      marginLeft: "5vw",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.inviteButton();
                      if (this.state.email) this.handleCloseDialog();
                    }}
                  >
                    Invite
                  </Button>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCloseDialog} color="#1E32FA">
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={iniviteHasDone}
              autoHideDuration={2000}
              onClose={this.handleCloseSnackBar}
            >
              <SnackbarContent
                style={{
                  backgroundColor: "green",
                }}
                message={
                  <span id="message-id">
                    Your Invitation has been sent successfully
                  </span>
                }
                action={[
                  <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    className={classes.close}
                    onClick={this.handleCloseSnackBar}
                  >
                    <CloseIcon />
                  </IconButton>,
                ]}
              />
            </Snackbar>
          </Paper>
        </div>
      </Layout>
    );
  }
}

export default withRouter(UserAndPermissions);

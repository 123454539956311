import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const CssTextField = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: " 10px 14px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(10px, 10px) scale(1)",
      color: "grey",
    },
  },
}))(TextField);
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export default function TextField2(props) {
  const classes = useStyles();
  const [author, setAuthor] = React.useState(
    props.selectedBlogData ? props.selectedBlogData.title : ""
  );
  const AddTextAuthor = (event) => {
    setAuthor(event.target.value);
    props.AddTextAuthor(event.target.value);
  };
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <CssTextField
        InputLabelProps={{
          shrink: false,
        }}
        label={author || props.authorName ? "" : "Add Author"}
        value={props.authorName ? props.authorName : author}
        autoComplete="off"
        variant="outlined"
        style={{
          width: "100%",
          margin: "0",
        }}
        onChange={(e) => {
          e.preventDefault();
          AddTextAuthor(e);
        }}
      />
    </form>
  );
}

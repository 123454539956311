const data = {
  allAccessLists: [
    { name: "institutes", label: "institutes" },
    { name: "subjectmaster", label: "subjectmaster" },
    { name: "website", label: "website" },
    { name: "settings", label: "Settings" },
    { name: "allteacherslist", label: "allteacherslist" },
  ],
  access: {
    institutes: false,
    website: false,
    subjectmaster: false,
    settings: false,
    allteacherslist: false,
  },
};
export default data;

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 15,
    position: "relative",
    backgroundColor: "white",
    boxShadow: "0 0.05rem 0.05rem 0.05rem #e0e0e0",
    border: "1px solid #00000029",
    padding: "15px",
    "&:focus": {
      borderRadius: 15,
      boxShadow: "0 0.05rem 0.05rem 0.05rem #e0e0e0",
      backgroundColor: "white",
    },
  },
}))(InputBase);

const styles = (theme) => ({
  bootstrapFormLabel: {
    fontSize: "0.9rem",
    transform: "translate(18px, 19px) scale(1)",
  },
  dividerCss: {
    height: "1px",
    margin: "0 4%",
  },
  subHeaderCss: { fontSize: "0.9rem" },
});

class SelectClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      name: null,
      selectedInstituteClasses: "",
    };
  }

  handleChange = (name, id) => {
    if (this.props.files === true) {
      this.props.fetchInstitutesFiles(id);
    }
    if (this.props.students === true) {
      this.props.fetchInstitutesStudents(id);
    }
    if (this.props.sessions === true) {
      this.props.fetchInstitutesSessions(id);
    }
    if (this.props.chat === true) {
      this.props.fetchClassSubjects(id);
    }

    this.setState({ name: name, id: id });
  };

  handleSetOpen = () => {
    this.setState({ open: true });
  };

  handleSetClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { selectedInstituteClasses, classes } = this.props;

    const { name } = this.state;
    return (
      <>
        <form
          style={{
            width: "100%",
          }}
          autoComplete="off"
        >
          <FormControl
            style={{ width: "30%", margin: "15px 0" }}
            variant="filled"
            className={classes.formControl}
          >
            <InputLabel
              shrink={false}
              htmlFor="grouped-select"
              className={classes.bootstrapFormLabel}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1",
                }}
              >
                {name != null ? name : "select class"}
              </div>
            </InputLabel>
            <Select
              onOpen={this.handleSetOpen}
              open={this.state.open}
              onClose={this.handleSetClose}
              id="grouped-select"
              input={<BootstrapInput id="age-customized-native-simple" />}
            >
              {selectedInstituteClasses?.map((option) => (
                <MenuItem
                  key={option.id}
                  onClick={(e) => {
                    this.handleSetClose();
                    this.handleChange(option.name, option.id);
                  }}
                  style={{
                    fontWeight: 500,
                    fontSize: "0.9rem",
                  }}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
      </>
    );
  }
}

SelectClass.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(SelectClass));

const configWebsite = {
  apiKey: "AIzaSyBbQixb34x35jBAHNWlP4YVcHkpuHJMDEU",
  authDomain: "netpractice-web.firebaseapp.com",
  databaseURL: "https://netpractice-web.firebaseio.com",
  projectId: "netpractice-web",
  storageBucket: "netpractice-web.appspot.com",
  messagingSenderId: "1063070706229",
  appId: "1:1063070706229:web:fd265760e570ff10eadf7c",
  measurementId: "G-0MEXVC1E4R",
};

export default configWebsite;

/** @format */

import React, { Component } from "react";
import { withRouter } from "react-router";
import Moment from "moment";
import PropTypes from "prop-types";
import Layout from "../layouts/Layout";
import { Showmore, ChatContactIcon, SearchIcon ,UserIcon } from "./Icons/ChatIcon";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import SingleSubjectChat from "./SingleSubjectChat";
import SingleClassGroupChat from "./SingleClassGroupChat";

import {
  Typography,
  Fab,
  ListItemText,
  ListItemIcon,
  ListItem,
  CircularProgress,
  List,
  withStyles,
  Avatar,
  InputBase,
  Button,
  Paper,
  IconButton,
} from "@material-ui/core";

const styles = {
  subjectChatAvtar: (isMobile) => ({
    backgroundColor: "#27C8D9",
    width: isMobile === true ? "" : "35px",
    height: isMobile === true ? "" : "35px",
  }),
  heading: {
    fontWeight: "18",
    fontSize: "1.1rem",
    margin: "0 5.3% ",
    color: "var(--darkGrey)",
  },
  allClassAnnouncements: {
    backgroundColor: "#707070",
  },
  classAnnouncements: {
    color: "#1E32FA",
    // backgroundColor: "#1E32FA",
  },

  messegeNotificationAvatar: {
    color: '#FFFFFF',
    backgroundColor: "#1E32FA",
    borderRadius: "50%",
    width: "18px",
    height: "18px",
    fontSize: "0.7rem",
    textAlign: "center",
    justifyContent: "center",
  },
  chatsInformation: {
    maxWidth: "90%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },

  chatList: {
    padding: "0 2%",
  },
  lastMessege: (isMobile) => ({
    marginTop: "0",
    wordBreak: "break-word",
    color: "#0000008A",
    fontSize: isMobile === true ? "1rem" : "0.8rem",
    width: "80%",
    fontFamily: "Poppins",
  }),
};

export class ChatTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classAnnouncementGroups: [],
      subjectChatGroups: [],
      chatPage: false,
      groupChatPage: false,
    };
  }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (
  //     nextProps.subjectChatGroupss !== prevState.subjectChatGroups ||
  //     nextProps.classAnnouncementGroups !== prevState.classAnnouncementGroups
  //   ) {
  //     return {
  //       subjectChatGroups: nextProps.subjectChatGroups,
  //       classAnnouncementGroups: nextProps.classAnnouncementGroups,
  //     };
  //   }
  // }
  // componentDidUpdate(prevProps, nextProps) {
  //   console.log(nextProps.classAnnouncementGroups,"nextProps.classAnnouncementGroups")
  //   console.log(nextProps.subjectChatGroups,"nextProps.subjectChatGroups")
  //   if (prevProps !== this.props) {
     
  //    //console.log(nextProps.subjectChatGroups,"nextProps.subjectChatGroups")
  //     this.setState({
  //       subjectChatGroups: nextProps.subjectChatGroups,
  //       classAnnouncementGroups: nextProps.classAnnouncementGroups,
  //     });
  //   }
  // }

  componentWillReceiveProps(next) {
 
    if (next) {
      this.setState({
        subjectChatGroups: next.subjectChatGroups,
        classAnnouncementGroups: next.classAnnouncementGroups,
      });
   
    }
  }
  selectedClassname = (selectedClassName, subjectId, subjectName, classId) => {
    const uid = this.props.user;
    this.props.dispatchSubjectName(uid, subjectId);
    this.props.ClassGroup(uid, classId);
  };
  clickedChatWhereNotSender = (chatInedx) => {
    if (
      this.state.subjectChatGroups.filter(
        (selectedChat) => (selectedChat.id = chatInedx)
      )[0].groupMesseges.length > 0
    ) {
      const data = this.state.subjectChatGroups.filter(
        (selectedChat) => (selectedChat.id = chatInedx)
      );
      if (
        data[0].groupMesseges[data[0].groupMesseges.length - 1].createdBy !==
        this.props.user.uid
      ) {
        if (
          data[0].groupMesseges[data[0].groupMesseges.length - 1].uids[
            this.props.user.uid
          ] === false
        ) {
          const subjectDocKey = chatInedx;
          const messegeDocKey =
            data[0].groupMesseges[data[0].groupMesseges.length - 1].id;
          this.props.updateMessegeHasRead(
            subjectDocKey,
            messegeDocKey,
            this.props.user.uid
          );
        }
      }
    }
  };
  render() {
  
    const { classes, loadingGroup } = this.props;
    const {
      classAnnouncementGroups,
      classAnnouncementChatGroups,
      subjectSelectedChatGroups,
     subjectChatGroups,
      chatPage,
      groupChatPage,
    } = this.state;
    const { isMobile} = this.props;
    return (
      
        
        <div
          className={
            isMobile === true
              ? "all-chat-groups-container"
              : "desktop-all-chats-container"
          }
        >
          <div
            className={
              isMobile === true ? "" : "leftside-desktop-all-chats-container"
            }
          >
            {loadingGroup === true ? (
              <div className="circularProgress-container">
                <CircularProgress />
              </div>
            ) : (
              <>
                <h3
                  className={
                    isMobile === true ? "heading-mobile" : "heading-web"
                  }
                >
                  Class Announcements
                </h3>
                {isMobile === true ? (
                  ""
                ) : (
                  <Paper
                    style={{
                      padding: isMobile === true ? "1%" : "",
                      width: isMobile === true ? "" : "100%",
                      margin: isMobile === true ? "0% 10%" : "20px 0 11px 0",
                      borderRadius: "7px",
                      display: "flex",
                    }}
                    elevation={3}
                    component="form"
                  >
                    <IconButton type="submit" aria-label="search">
                      <SearchIcon chat={true} />
                    </IconButton>
                    <InputBase
                      // style={styles.input}
                      placeholder={
                        this.props.pendingStudentField === true
                          ? "search requests"
                          : "search students"
                      }
                      inputProps={{ "aria-label": "search google maps" }}
                    />
                  </Paper>
                )}
                <div
                  className={
                    isMobile === true
                      ? "class-announcment-groups-container"
                      : "desktop-class-announcment-groups-container"
                  }
                >
                  {classAnnouncementGroups?.map((classAnnouncementGroups) => (
                    <div
                      key={classAnnouncementGroups.id}
                      className="single-class-announcment-group-container"
                    >
                      <Fab
                        onClick={(e) => {
                          if (isMobile === true) {
                            e.preventDefault();
                            this.props.history.push(
                              `/chats/${classAnnouncementGroups.id}`
                            );
                          }
                          if (isMobile === false) {
                            this.setState({
                              groupChatPage: true,
                              chatPage: false,
                              classAnnouncementChatGroups: classAnnouncementGroups,
                            });
                          }
                          this.props.enterClassAnnouncementsChat(
                            classAnnouncementGroups
                          );
                        }}
                        color={
                          classAnnouncementGroups.read === false
                            ? "secondary"
                            : "primary"
                        }
                        size={isMobile == true ? "medium" : "small"}
                        style={{
                          fontWeight: "bold",
                          border:
                            classAnnouncementGroups.read === false
                              ? ""
                              : "2.5px solid #1E32FA",
                          backgroundColor:
                            classAnnouncementGroups.read === false
                              ? "#1E32FA"
                              : "#f5f8fc",
                          //theme.palette.getContrastText(
                          color:
                            classAnnouncementGroups.read === false
                              ? "#f5f8fc"
                              : "#1E32FA",
                          //),
                          textColor: "primary",
                        }}
                        // style={styles.classAnnouncements}
                      >
                        <UserIcon
                          color={
                            classAnnouncementGroups.read === false
                              ? '#FFFFFF'
                              : "#1E32FA"
                          }
                          isMobile={isMobile}
                        />
                        {/* {classAnnouncementGroups.name.slice(6)} */}
                      </Fab>

                      <span
                        className={
                          isMobile === true
                            ? "class-announcment-groups-name"
                            : "desktop-class-announcment-groups-name"
                        }
                      >
                        {classAnnouncementGroups.name.charAt(0).toUpperCase() +
                          classAnnouncementGroups.name.slice(1)}
                      </span>
                    </div>
                  ))}
                  {classAnnouncementGroups?.length >= 4 ? (
                    <div className="single-class-announcment-group-container">
                      <Fab
                        color="secondary"
                        size={isMobile == true ? "medium" : "small"}
                        style={styles.allClassAnnouncements}
                      >
                        <Showmore />
                      </Fab>
                      <span className="class-announcment-groups-name">
                        Show All
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <h3
                  className={
                    isMobile === true ? "heading-mobile" : "heading-web-2"
                  }
                >
                  Subject Discussion Groups
                </h3>
                <div
                  className={
                    isMobile === true
                      ? "subject-chat-groups-container"
                      : "web-subject-chat-groups-container"
                  }
                >
                  {subjectChatGroups?.map((subjectChatGroups) => (
                    <List
                      style={{ padding: "0 0 2% 0" }}
                      key={subjectChatGroups.id}
                    >
                      <ListItem
                        onClick={(e) => {
                          if (isMobile === true) {
                            e.preventDefault();
                            this.props.history.push(
                              `/chats/subject/${subjectChatGroups.id}`
                            );
                          }
                          if (isMobile === false) {
                            this.setState({
                              chatPage: true,
                              groupChatPage: false,
                              subjectSelectedChatGroups: subjectChatGroups,
                            });
                          }

                          // this.clickedChatWhereNotSender(subjectChatGroups.id);
                          this.props.enterSubjectChat(subjectChatGroups);
                        }}
                        style={styles.chatList}
                      >
                        <ListItemIcon>
                          <Avatar style={styles.subjectChatAvtar(isMobile)}>
                            <ChatContactIcon />
                          </Avatar>
                        </ListItemIcon>
                        <ListItemText
                          style={styles.chatsInformation}
                          primary={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                component={"span"}
                                style={{
                                  fontSize:
                                    isMobile === true ? "1.1rem" : "0.8rem",
                                  display: "inline-flex",
                                  color: "#000000DE",
                                  fontFamily: "Poppins",
                                }}
                              >{subjectChatGroups.className}
                                {/* {subjectChatGroups.className
                                  .charAt(0)
                                  .toUpperCase() +
                                  subjectChatGroups.className.slice(1) +
                                  " - "} */}
                                {/* {subjectChatGroups.name
                                  .charAt(0)
                                  .toUpperCase() +
                                  subjectChatGroups.name.slice(1)} */}
                              </Typography>{" "}
                              <Typography
                                style={{
                                  fontSize:
                                    isMobile === true ? "1rem" : "0.8rem",
                                  flexDirection: "column-reverse",
                                  display: "flex",
                                  height: "auto",
                                  color: "#0000008A",
                                  fontFamily: "Poppins",
                                }}
                                component={"span"}
                              >
                                {/* {subjectChatGroups.groupMesseges?.length === 0
                                  ? " "
                                  : Moment(
                                      subjectChatGroups.groupMesseges[0]
                                        .createdAt.seconds * 1000
                                    ).format("ll") ===
                                    Moment(new Date()).format("ll") //firebase.firestore.Timestamp.now()
                                  ? Moment(
                                      subjectChatGroups.groupMesseges[0].createdAt.toDate()
                                    ).format("LT")
                                  : Moment(
                                      subjectChatGroups.groupMesseges[0]
                                        .createdAt.seconds * 1000
                                    ).format("ll") ===
                                    Moment(new Date())
                                      .subtract(1, "days")
                                      .format("ll")
                                  ? "yesterday"
                                  : Moment(
                                      subjectChatGroups.groupMesseges[0]
                                        .createdAt.seconds * 1000
                                    ).format("DD/MM/YY")} */}
                              </Typography>
                            </div>
                          }
                          secondary={
                            <Typography
                              component={"div"}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                component={"span"}
                                style={styles.lastMessege(isMobile)}
                              >
                                {/* {subjectChatGroups.groupMesseges.length ===
                                0 ? (
                                  " "
                                ) : (
                                  <>
                                    {subjectChatGroups.groupMesseges[0]
                                      .createdBy === this.props.user.uid
                                      ? "you : "
                                      : subjectChatGroups.groupMesseges[0]
                                          .senderName + " : "}
                                    {/* if messege is long 
                                    {subjectChatGroups.groupMesseges[0].messege.slice(
                                      0,
                                      56
                                    )}
                                  </>
                                )} */}
                              </Typography>
                              <Typography
                                component={"span"}
                                style={{
                                  textAlign: "center",
                                  width: "67px",
                                  display: "flex",
                                  justifyContent: "center",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {/* {subjectChatGroups.groupMesseges.length ===
                                0 ? (
                                  " "
                                ) : subjectChatGroups.groupMesseges[0]
                                    .createdBy === this.props.user.uid ? (
                                  <DoneAllIcon
                                    color="primary"
                                    fontSize="small"
                                    style={{ marginTop: "2%" }}
                                  />
                                ) : subjectChatGroups.groupMesseges[0].uids[
                                    this.props.user.uid
                                  ] === false &&
                                  subjectChatGroups.groupMesseges.filter(
                                    (p) => p.uids[this.props.user.uid] === false
                                  ).length > 0 ? (
                                  <Avatar
                                    style={styles.messegeNotificationAvatar}
                                  >
                                    {subjectChatGroups.groupMesseges.filter(
                                      (p) =>
                                        p.uids[this.props.user.uid] === false
                                    ).length <= 5
                                      ? subjectChatGroups.groupMesseges.filter(
                                          (p) =>
                                            p.uids[this.props.user.uid] ===
                                            false
                                        ).length
                                      : "5+"}
                                  </Avatar>
                                ) : (
                                  ""
                                )} */}
                              </Typography>
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                  ))}
                </div>
              </>
            )}
          </div>
          {isMobile === false ? (
            <div className={"rightside-desktop-all-chats-container"}>
            
              {chatPage === true ? (
                <SingleSubjectChat
                  isMobile={isMobile}
                  subjectSelectedChatGroups={subjectSelectedChatGroups}
                  user={this.props.user}
                  subjectMesseges={this.props.subjectMesseges}
                />
              ) : (
                ""
              )}
              {groupChatPage === true ? (
                <SingleClassGroupChat
                groupMesseges={this.props.groupMesseges}
                  isMobile={isMobile} user={this.props.user}
                  classChatsData={classAnnouncementChatGroups}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      
    );
  }
}
ChatTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(ChatTable));

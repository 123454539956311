import { Route } from "react-router-dom";
import WebsiteContactDetails from "../components/WebsiteContactDetails";
import withFirebase from "../hoc/withFirebase";
import withUser from "../hoc/withUser";
import React, { Component } from "react";

class WebsiteContactContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactUsWebsite: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.fetchWebsiteContact();
  }

  fetchWebsiteContact = (createdBy) => {
    this.props.dbWeb
      .collection("contactUsWebsite")
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        const contactUsWebsite = [];
        querySnapshot.forEach((doc) => {
          const singleContactDetail = doc.data();
          singleContactDetail.id = doc.id;
          contactUsWebsite.push(singleContactDetail);
        });
        this.setState({ contactUsWebsite: contactUsWebsite });
      });
  };
  render() {
    return (
      <Route
        exact
        path={[
          "/websiteContacts",
          "/websiteContacts/:websiteContactsType/:id",
          "/websiteContacts/new",
        ]}
        render={(props) => (
          <WebsiteContactDetails
            fetchWebsiteContact={this.fetchWebsiteContact}
            {...this.props}
            {...this.state}
          />
        )}
      />
    );
  }
}

export default withFirebase(withUser(WebsiteContactContainer));

import React from 'react';
export const SearchIcon = (props) => (
	<svg
		style={{
			color          : '#404040',
			fill           : 'none',
			stroke         : '#404040',
			strokeLinecap  : 'round',
			strokeLinejoin : 'round',
			strokeWidth    : '2px'
		}}
		xmlns="http://www.w3.org/2000/svg"
		width="18.414"
		height={props.chat === true ? '14.414' : '18.414'}
		viewBox="0 0 18.414 18.414">
		<g transform="translate(-2 -2)">
			<circle cx="7.111" cy="7.111" r="7.111" transform="translate(3 3)" />
			<line x1="3.867" y1="3.867" transform="translate(15.133 15.133)" />
		</g>
	</svg>
);

export const Showmore = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="8.414" height="14.828" viewBox="0 0 8.414 14.828">
		<defs />
		<path
			style={{
				fill           : 'none',
				stroke         : '#fff',
				strokeLinecap  : 'round',
				strokeLinejoin : 'round',
				strokeWidth    : '2px'
			}}
			d="M9,18l6-6L9,6"
			transform="translate(-7.586 -4.586)"
		/>
	</svg>
);
export const ChatContactIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22">
		<defs />
		<g transform="translate(-3 -1)">
			<path
				style={{
					fill           : 'none',
					stroke         : '#fff',
					strokeLinecap  : 'round',
					strokeLinejoin : 'round',
					strokeWidth    : '2px'
				}}
				d="M4,19.5A2.5,2.5,0,0,1,6.5,17H20"
			/>
			<path
				style={{
					fill           : 'none',
					stroke         : '#fff',
					strokeLinecap  : 'round',
					strokeLinejoin : 'round',
					strokeWidth    : '2px'
				}}
				d="M6.5,2H20V22H6.5A2.5,2.5,0,0,1,4,19.5V4.5A2.5,2.5,0,0,1,6.5,2Z"
			/>
		</g>
	</svg>
);
export const ArrowBackIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.828" viewBox="0 0 16 16.828">
		<defs />
		<g transform="translate(-4 -3.586)">
			<line
				style={{
					fill           : 'none',
					stroke         : '#404040',
					strokeLinecap  : 'round',
					strokeLinejoin : 'round',
					strokeWidth    : '2px'
				}}
				x1="14"
				transform="translate(5 12)"
			/>
			<path
				style={{
					fill           : 'none',
					stroke         : '#404040',
					strokeLinecap  : 'round',
					strokeLinejoin : 'round',
					strokeWidth    : '2px'
				}}
				d="M12,19,5,12l7-7"
			/>
		</g>
	</svg>
);

export const SendIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="18.414" height="18.414" viewBox="0 0 18.414 18.414">
		<g transform="translate(-1 -0.586)">
			<line
				style={{
					fill           : 'none',
					stroke         : '#fff',
					strokeLinecap  : 'round',
					strokeLinejoin : 'round',
					strokeWidth    : '2px'
				}}
				x1="8.8"
				y2="8.8"
				transform="translate(9.2 2)"
			/>
			<path
				style={{
					fill           : 'none',
					stroke         : '#fff',
					strokeLinecap  : 'round',
					strokeLinejoin : 'round',
					strokeWidth    : '2px'
				}}
				d="M18,2,12.4,18,9.2,10.8,2,7.6Z"
			/>
		</g>
	</svg>
);
export const UserIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height={props.isMobile === true ? '24' : '19'}
		viewBox="0 0 24 24"
		fill="none"
		stroke={props.color ? props.color : 'currentColor'}
		stroke-width="2"
		strokeLinecap="round"
		strokeLinejoin="round"
		class="feather feather-users">
		<path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
		<circle cx="9" cy="7" r="4" />
		<path d="M23 21v-2a4 4 0 0 0-3-3.87" />
		<path d="M16 3.13a4 4 0 0 1 0 7.75" />
	</svg>
);

import { Paper } from "@material-ui/core";
import React, { Component } from "react";
import Layout from "../layouts/Layout";
import data from "./data/AllAccessKey";
import "./Design.css";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  TextField,
  Button,
  DialogActions,
  DialogContent,
  Slide,
  Snackbar,
  SnackbarContent,
  IconButton,
  Checkbox,
  Tooltip,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ReactDOM from "react-dom";
import SnackbarComponent from "./SnackbarComponent";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: " darkerGrey,",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "darkerGrey",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "darkerGrey",
      },
      "&:hover fieldset": {
        borderColor: "darkerGrey",
      },
      "&.Mui-focused fieldset": {
        borderColor: "darkerGrey",
      },
    },
  },
})(TextField);
const styles = {
  textField: {
    width: "100%",
    margin: "30px 0 0",
    borderColor: "var(--grey)",
    backgroundColor: "var(--white)",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  Snackbar: {
    backgroundColor: "green",
  },
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
    height: "100%",
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));
export class WebsiteFaqDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      websiteFaq: "",
      selectedwebsiteFaq: [],
      selectedwebsiteFaq: null,
      addSubjects: false,
      subjectAdded: false,
      name: "",

      iniviteHasDone: false,
      openSnackbarForUpdate: false,
      // You can also pass a Quill Delta here, when you use edit mode for any blog, you can simply pass old delta here.

      tabs: [
        {
          label: "Website Faq",
          tooltip: "Click to for faq's data",
          data: props.users,
          route: `/faq's/faq'sType`,
          primaryField: "name",
          secondaryField: "email",
          avatarField: "imageURL",
        },
      ],
    };
  }

  UNSAFE_componentWillReceiveProps(next) {
    const tabData = this.state.tabs;
    if (next) {
      tabData[0].data = next.websiteFaq;

      this.setState({
        tabs: tabData,
        websiteFaq: next.websiteFaq,
      });
      console.log(next.websiteFaq, "anu");
    }
  }
  listClickHandler = (data) => {
    // this.props.fetchwebsiteFaq(data.id);
    // const selectedwebsiteFaq = this.state.websiteFaq.filter(
    //   (websiteFaq) => websiteFaq.createdAt === data.id
    // );

    this.setState({ selectedwebsiteFaq: data, addSubjects: false });
    console.log(data, "fff");
  };
  closePage = () => {
    this.setState(
      {
        selectedwebsiteFaq: null,
        subjectAdded: false,
        addSubjects: null,
      },
      () => {
        this.props.history.push("/faq's");
      }
    );
  };
  handleCloseSnackBar = () => {
    this.setState({ iniviteHasDone: false, openSnackbarForUpdate: false });
  };
  inviteButton = () => {
    console.log(this.state.selectedwebsiteFaq, "selectedwebsiteFaq");
    if (this.state.selectedwebsiteFaq.email && this.state.name) {
      console.log(
        this.state.name,
        this.state.selectedwebsiteFaq.email,
        " this.state.selectedwebsiteFaq.emailss"
      );
      this.props.firebase
        .sendInvitationAdmin({
          text: this.state.selectedwebsiteFaq.email,
          message: this.state.name,
          userMessage: this.state.selectedwebsiteFaq.message,
        })
        .then((result) => {
          console.log("result", result);
        })
        .catch((error) => {
          console.log("error", error);
        });
      this.setState({ iniviteHasDone: true, name: " " });
    } else alert("Please enter an your message");
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const {
      tabs,
      websiteFaq,
      selectedwebsiteFaq,
      openSnackbarForUpdate,
      subjectAdded,
      iniviteHasDone,
      addSubjects,
    } = this.state;
    const classes = useStyles;
    return (
      <Layout
        tabs={tabs}
        search={{
          data: this.websiteFaq,

          hintText: "Search FAQDetail", // Optional
          labelField: "name",
        }}
        tabs={tabs}
        listClickHandler={this.listClickHandler}
      >
        {selectedwebsiteFaq ? (
          <Paper
            style={{
              height: window.innerHeight - 90,
              margin: "20 0 20 0",
              width: "fill-available",
              overflowY: "scroll",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  height: 100,
                  width: "100%",
                  border: `2px solid #1E32FA`,
                  marginBottom: 10,
                  borderRadius: "12px",
                  textAlign: "center",
                }}
              >
                <CloseIcon
                  style={{
                    display: "flex",
                    //top: "15px",
                    //right: "30px",
                    position: "absolute",
                  }}
                  variant="extended"
                  size="small"
                  aria-label="close"
                  onClick={(e) => {
                    e.preventDefault();
                    this.closePage();
                  }}
                />
                <h2>Website FAQS Details</h2>
              </div>
            </div>

            <Table size="small" style={{ marginTop: 10 }}>
              <TableHead>
                {/* <TableRow>
                  <TableCell align="left">
                    <h2>Section</h2>
                  </TableCell>
                </TableRow> */}
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <div style={{ display: "flex", margin: "10px" }}>
                      <span
                        style={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          flex: " 1 1 0%",
                        }}
                      >
                        Name
                      </span>
                      <span
                        style={{
                          flex: "1 1 0%",
                          fontSize: "1rem",
                          whiteSpace: " pre-line",
                        }}
                      >
                        {selectedwebsiteFaq?.name && selectedwebsiteFaq.name}
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <div style={{ display: "flex", margin: "10px" }}>
                      <span
                        style={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          flex: " 1 1 0%",
                        }}
                      >
                        Email{" "}
                      </span>
                      <span
                        style={{
                          flex: "1 1 0%",
                          fontSize: "1rem",
                          whiteSpace: " pre-line",
                        }}
                      >
                        {selectedwebsiteFaq.email}
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <div style={{ display: "flex", margin: "10px" }}>
                      <span
                        style={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          flex: " 1 1 0%",
                        }}
                      >
                        PhoneNumber{" "}
                      </span>
                      <span
                        style={{
                          flex: "1 1 0%",
                          fontSize: "1rem",
                          whiteSpace: " pre-line",
                        }}
                      >
                        {selectedwebsiteFaq.phoneNumber}{" "}
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <div style={{ display: "flex", margin: "10px" }}>
                      <span
                        style={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          flex: " 1 1 0%",
                        }}
                      >
                        Message or Question{" "}
                      </span>
                      <span
                        style={{
                          flex: "1 1 0%",
                          fontSize: "1rem",
                          whiteSpace: " pre-line",
                        }}
                      >
                        {selectedwebsiteFaq.message}{" "}
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <div
              style={{
                display: " flex",

                // padding: "10px 0 0 35px",
                // marginBottom: "5px",
                justifyContent: "flex-end",
                margin: "10px",
              }}
            >
              <div
                style={{
                  margin: "20px 30px 0 0",
                }}
              >
                <Button
                  style={{ backgroundColor: "rgb(30, 50, 250)" }}
                  onClick={() => {
                    // this.props.history.push("/faq's");
                    this.setState({
                      addSubjects: true,
                      // selectedwebsiteFaq: s"",
                    });
                  }}
                  variant="contained"
                  color="primary"
                >
                  Reply
                </Button>
              </div>
            </div>

            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={openSnackbarForUpdate}
              autoHideDuration={2000}
              onClose={this.handleCloseSnackBar}
            >
              <SnackbarContent
                style={{
                  backgroundColor: "lightblue",
                }}
                message={<span id="message-id">successfully updated</span>}
                action={[
                  <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    className={classes.close}
                    onClick={this.handleCloseSnackBar}
                  >
                    <CloseIcon />
                  </IconButton>,
                ]}
              />
            </Snackbar>
          </Paper>
        ) : (
          <Paper
            style={{
              height: 500,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3 style={{ padding: 0 }}>You Have No Data To Show</h3>
          </Paper>
        )}
        {addSubjects == true ? (
          <>
            <Dialog
              ref={(e) => {
                try {
                  const target = ReactDOM.findDOMNode(e).children[2]
                    .children[0];
                  target.style.borderRadius = "20px";
                  target.style.width = "50%";
                  target.style.maxWidth = "100%";
                  target.style.height = "60%";
                  target.style.margin = "70px 20px 20px 20px";
                  target.style.padding = "20px";
                } catch (e) {
                  console.log(e);
                }
              }}
              scroll={"paper"}
              open={true}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
            >
              <div
                style={{
                  position: "fixed",
                  left: "48%",
                  top: "50px",
                  height: "30%",
                  width: "30%",
                }}
              >
                {/* <DialogSubjectIcon /> */}
              </div>
              <DialogActions
                style={{
                  display: "flex",
                  margin: "0 0 0px 0 ",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  fontSize: "1rem",
                  color: "grey",
                }}
              >
                <div
                  style={{
                    width: "65%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      margin: "10px 15px",
                      fontSize: "1.0rem",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    {subjectAdded === true
                      ? "Subject has been added "
                      : "Reply on Message or Question"}
                  </span>
                  {/* <span
                    style={{
                      margin: "0px 15px",
                      fontSize: "0.8rem",
                      fontWeight: "300",
                      color: "grey",
                    }}
                  >
                    {subjectAdded === true
                      ? "Go to class tab for assigning subjects "
                      : "create new subject for class"}
                  </span> */}
                </div>

                <Tooltip title="close" arrow placement="top">
                  <CloseIcon
                    style={{
                      display: "flex",
                      top: "15px",
                      right: "20px",
                      position: "absolute",
                    }}
                    variant="extended"
                    size="small"
                    aria-label="close"
                    onClick={(e) => {
                      e.preventDefault();
                      this.closePage();
                    }}
                  />
                </Tooltip>
              </DialogActions>

              <DialogContent
                style={{
                  padding: "0px 20px",
                  justifyContent: "center",
                }}
              >
                <div>
                  <CssTextField
                    autoComplete="off"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // autoFocus={true}
                    multiline={true}
                    rowsMax={8}
                    placeholder="reply..."
                    type="text"
                    style={styles.textField}
                    required={true}
                    onChange={this.handleChange}
                    value={this.state.message}
                    label="message"
                    variant="outlined"
                    name="name"
                  />
                </div>{" "}
                {subjectAdded === true ? (
                  <div
                    style={{
                      margin: "50px 23px",
                      display: "flex",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {/* {<CheckCircle subjectTab={true} />} */}
                    <span
                      style={{
                        color: "#17C261",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "30px",
                      }}
                    >
                      Message has been sent
                    </span>
                  </div>
                ) : (
                  <></>
                  // <div style={{ margin: "10px 20px", width: "100%" }}>
                  //   <CssChipInput
                  //     value={this.state.subjectsArr}
                  //     onAdd={(chip) => this.handleAddSubject(chip)}
                  //     onDelete={(chip, index) =>
                  //       this.handleDeleteChip(chip, index)
                  //     }
                  //     label="Add Subject"
                  //     autoFocus={true}
                  //     style={{
                  //       marginTop: "0%",
                  //     }}
                  //     allowDuplicates={false}
                  //     blurBehavior="clear"
                  //     clearInputValueOnChange={false}
                  //     delayBeforeAdd={false}
                  //     disableUnderline={true}
                  //     variant="outlined"
                  //   />
                  // </div>
                )}{" "}
              </DialogContent>
              <DialogActions
                style={{
                  padding: "15px",
                }}
              >
                <Button
                  onClick={() => {
                    if (subjectAdded === true) {
                      this.closePage();
                    } else {
                      if (this.state.subjectsArr.length > 0) {
                        this.props.addData(this.state.subjectsArr);
                        this.setState({ subjectAdded: true });
                      } else {
                        this.setState({ openErrorSnackBar: true });
                      }
                    }
                  }}
                  // onClick={() => {
                  //   if (subjectAdded === true) {
                  //     this.handleClose();
                  //   } else {
                  //     // this.props.addNewSubjectFunction(
                  //     //         this.props.user.user.uid,
                  //     //
                  //     //       );
                  //     if (this.state.subjectsArr.length > 0) {
                  //       this.props.addData(
                  //         this.state.subjectsArr
                  //         // {
                  //         //   name: name,

                  //         //   status: "approved",
                  //         // }
                  //         // selectedSubject
                  //       );
                  //     }
                  //   }
                  // }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.inviteButton();
                    if ((this.state.email, this.state.message))
                      this.handleCloseDialog();
                  }}
                  variant="contained"
                  style={{
                    backgroundColor: "rgb(30, 50, 250)",
                    width: "25%",
                    borderRadius: "10px",
                    color: "white",
                    textTransform: "capitalize",
                    margin: "0 15px 0 0",
                  }}
                >
                  Send via Email
                </Button>
                <Snackbar
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  open={iniviteHasDone}
                  autoHideDuration={2000}
                  onClose={this.handleCloseSnackBar}
                >
                  <SnackbarContent
                    style={{
                      backgroundColor: "green",
                    }}
                    message={
                      <span id="message-id">
                        Your Invitation has been sent successfully
                      </span>
                    }
                    action={[
                      <IconButton
                        key="close"
                        aria-label="close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.handleCloseSnackBar}
                      >
                        <CloseIcon />
                      </IconButton>,
                    ]}
                  />
                </Snackbar>
                {this.state.openErrorSnackBar === true ? (
                  <SnackbarComponent
                    error={true}
                    message={"Add all fields!"}
                    handleCloseSnackbar={this.handleErrorCloseSnackbar}
                  />
                ) : (
                  ""
                )}
              </DialogActions>
            </Dialog>
          </>
        ) : (
          ""
        )}
      </Layout>
    );
  }
}

export default WebsiteFaqDetails;

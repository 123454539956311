import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Chip } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  IconButton,
  ListItemText,
  ListItemIcon,
  ListItem,
  Avatar,
  ListItemSecondaryAction,
  List,
  Checkbox,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ChipInput from "material-ui-chip-input";

const styles = (theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "33%",
    height: theme.spacing(17),
    borderRadius: "10px",
  },
  chipCss: {
    margin: "7px",
    backgroundColor: "#D3D3D3",
    color: "white",
    borderRadius: "5px",
    width: "100%",
  },
  spanStyle: {
    fontFamily: "Poppins",
    color: "grey",
  },
  selectClass: {
    marginLeft: "2%",
  },
});
const CssTextField = withStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined": {
      transform: "translate(18.5px, 18.5px) scale(1)",
      color: "grey",
    },
    "&.MuiChip-deleteIconColorPrimary": {
      color: "black",
    },
  },
}))(TextField);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography style={{ margin: "0 -24px" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
    height: "100%",
    width: "100%",
  },
}));
const CssChip = withStyles((theme) => ({
  root: {
    "&.MuiChip-colorPrimary": {
      color: "black",
      backgroundColor: "#e0e0e0",
    },
    // "&.MuiChip-deleteIconColorPrimary": {
    //   color: "black",
    // },
    "&.MuiChip-deleteIconColorPrimary": {
      color: "none",
    },
  },
}))(Chip);

class DialogSubject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "all",
      selectedArray: [],
      subjectsArr: this.props.selectedBlogData
        ? this.props.selectedBlogData.categories
        : [],
      openErrorSnackBar: false,
      selectedSubject: [],
      subjectsMasterData: [
        { name: "school" },
        { name: "education" },
        { name: "school2" },
      ],
    };
  }
  componentWillMount() {
    console.log("insideeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
    const selectedArray = [];
    if (this.props.selectedBlogData) {
      if (this.props.selectedBlogData.categories) {
        console.log(this.props.selectedBlogData, "ffffffffffffffffffff");
        this.props.selectedBlogData.categories.map((data) =>
          selectedArray.push({ name: data })
        );
        this.setState({ selectedArray });
      }
    }
  }
  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };
  handleSelectSubject = (event, option) => {
    console.log(option, "optionoptionoption");
    let chipArr = [];
    option.map((opt) => chipArr.push(opt.name));
    console.log(chipArr, "chipArr");
    this.setState({ selectedSubject: option });
    // let chipArr = this.state.selectedSubject;
    // chipArr.push(option.name);

    this.props.blogCategory(chipArr);
  };
  handleAddSubject = (chip) => {
    console.log(chip, "hhhhhhhhhhh");
    let chipArr = this.state.subjectsArr;
    chipArr.push(chip);

    this.setState({ subjectsArr: chipArr });
  };
  handleErrorCloseSnackbar = () => {
    this.setState({ openErrorSnackBar: false });
  };
  handleDeleteChip = (chip, index) => {
    let chipArr = this.state.subjectsArr;
    chipArr.splice(index, 1);
    this.setState({ subjectsArr: chipArr });
  };
  render() {
    // const {  } = this.props;
    const { subjectAdded, subjectsMasterData, selectedArray } = this.state;
    return (
      <div style={{ margin: "0", width: "100%" }}>
        {console.log(this.state.selectedArray, "this.props.selectedBlogData?")}
        <Autocomplete
          style={{
            width: "100%",
            marginTop: "0",
            background: "white",

            padding: "0%",
          }}
          multiple
          id="tags-outlined"
          onChange={this.handleSelectSubject}
          options={subjectsMasterData}
          getOptionLabel={(option) => option.name}
          filterSelectedOptions
          defaultValue={
            this.state.selectedArray //? this.state.selectedArray : ""
          } //selectedArray}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <>
                <CssChip
                  style={styles.chipCss}
                  label={option.name}
                  clickable
                  color="primary"
                  {...getTagProps({ index })}
                />
              </>
            ))
          }
          renderInput={(params) => (
            <CssTextField
              {...params}
              InputLabelProps={{
                shrink: false,
              }}
              label={
                this.state.selectedSubject.length > 0 ||
                this.props.selectedBlogData?.categories?.length > 0
                  ? ""
                  : "Add Category"
              }
              variant="outlined"
            />
          )}
        />
      </div>
    );
  }
}

export default withRouter(DialogSubject);

// import React, { useState } from "react";
// import MultiSelect from "react-multiple-select-dropdown-lite";
// import "react-multiple-select-dropdown-lite/dist/index.css";

// const SelectBlog = () => {
//   const [value, setvalue] = useState("");

//   const handleOnchange = (val) => {
//     setvalue(val);
//   };

//   const options = [
//     { label: "Option 1", value: "option_1" },
//     { label: "Option 2", value: "option_2" },
//     { label: "Option 3", value: "option_3" },
//     { label: "Option 4", value: "option_4" },
//   ];

//   return (
//     <div className="app">
//       <div className="preview-values">
//         <h4>Values</h4>
//         {value}
//       </div>

//       <MultiSelect onChange={handleOnchange} options={options} />
//     </div>
//   );
// };
// export default SelectBlog;

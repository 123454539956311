import React, { Component } from "react";
import Layout from "../layouts/Layout";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teachers: [],
      selectedTeacher: [],
      selectedTeacher: null,

      tabs: [
        {
          label: "teachers",
          tooltip: "Click to approve intitutes",
          data: [],
          route: "/allteacherslist/user",
          avatarField: "imageUrl",
          primaryField: "userName",
          secondaryField: "identifier",
        },
      ],
    };
  }

  componentDidMount() {
    // this.setInitialValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(next) {
    const tabData = this.state.tabs;
    if (next) {
      tabData[0].data = next.teachers;

      this.setState({
        tabs: tabData,
        teachers: next.teachers,
      });
      console.log(next.teachers, "jjjjjjjjj");
    }
  }

  listClickHandler = (data) => {
    // this.props.fetchContact(data.id);

    const selectedTeacher = this.state.teachers.filter(
      (teachers) => teachers.createdBy === data.id
    );
    this.setState({ selectedTeacher, selectedTeacher: data });
  };
  closePage = (view) => {
    this.setState({ selectedTeacher: null });
  };
  render() {
    const { tabs, teachers, selectedTeacher } = this.state;
    return (
      <Layout
        search={{
          data: teachers, // Optional, In case if you not providing this, tabs data will be placed.
          hintText: "Search institutes", // Optional
          labelField: "name",
        }}
        tabs={tabs}
        listClickHandler={this.listClickHandler}
      >
        {selectedTeacher !== null ? (
          <>
            <div style={{ display: "flex", justifyContent: "flex-end" }} />
            <Paper style={{ height: "500px" }}>
              {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div
                  style={{
                    height: 100,
                    width: "100%",
                    border: `2px solid #1E32FA`,
                    marginBottom: 10,
                    borderRadius: "12px",
                    textAlign: "center",
                  }}
                >
                  <h4
                    style={{
                      fontSize: "20px",
                      margin: "0",
                      fontWeight: "300",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    NPC Contact Details
                  </h4>
                  <Tooltip
                    title="Close"
                    aria-label="add"
                    placement="top"
                    disableFocusListener
                    disableTouchListener
                    arrow
                  >
                    <CloseIcon
                      variant="extended"
                      size="small"
                      aria-label="close"
                      onClick={(e) => {
                        e.preventDefault();
                        this.closePage();
                      }}
                    />
                  </Tooltip>
                </div>
              </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginBottom: "20px",
                }}
              >
                {" "}
                <div
                  style={{
                    height: 100,
                    width: "100%",
                    border: `2px solid #1E32FA`,
                    marginBottom: 10,
                    borderRadius: "12px",
                    textAlign: "center",
                  }}
                >
                  <CloseIcon
                    style={{
                      display: "flex",
                      // top: "15px",
                      //right: "30px",
                      position: "absolute",
                    }}
                    variant="extended"
                    size="small"
                    aria-label="close"
                    onClick={(e) => {
                      e.preventDefault();
                      this.closePage();
                    }}
                  />
                  <h2> NPC Teachers Details</h2>
                </div>
              </div>
              {selectedTeacher ? (
                <>
                  <Table size="small" style={{ marginTop: 10 }}>
                    <TableHead>
                      {/* <TableRow>
                  <TableCell align="left">
                    <h2>Section</h2>
                  </TableCell>
                </TableRow> */}
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <div style={{ display: "flex", margin: "10px" }}>
                            <span
                              style={{
                                fontSize: "1rem",
                                fontWeight: "bold",
                                flex: " 1 1 0%",
                              }}
                            >
                              Name
                            </span>
                            <span
                              style={{
                                flex: "1 1 0%",
                                fontSize: "1rem",
                                whiteSpace: " pre-line",
                              }}
                            >
                              {selectedTeacher.userName}
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <div style={{ display: "flex", margin: "10px" }}>
                            <span
                              style={{
                                fontSize: "1rem",
                                fontWeight: "bold",
                                flex: " 1 1 0%",
                              }}
                            >
                              InstituteName{" "}
                            </span>
                            <span
                              style={{
                                flex: "1 1 0%",
                                fontSize: "1rem",
                                whiteSpace: " pre-line",
                              }}
                            >
                              {selectedTeacher.instituteName}
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell component="th" scope="row">
                          <div style={{ display: "flex", margin: "10px" }}>
                            <span
                              style={{
                                fontSize: "1rem",
                                fontWeight: "bold",
                                flex: " 1 1 0%",
                              }}
                            >
                              PhoneNumber{" "}
                            </span>
                            <span
                              style={{
                                flex: "1 1 0%",
                                fontSize: "1rem",
                                whiteSpace: " pre-line",
                              }}
                            >
                              {selectedTeacher.identifier}{" "}
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <div style={{ display: "flex", margin: "10px" }}>
                            <span
                              style={{
                                fontSize: "1rem",
                                fontWeight: "bold",
                                flex: " 1 1 0%",
                              }}
                            >
                              UserType{" "}
                            </span>
                            <span
                              style={{
                                flex: "1 1 0%",
                                fontSize: "1rem",
                                whiteSpace: " pre-line",
                              }}
                            >
                              {selectedTeacher.userType}{" "}
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                  {/* <h4
                    style={{
                      fontSize: "20px",
                      margin: "0",
                      fontWeight: "300",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {" "}
                    Name -{selectedTeacher.name}
                  </h4>
                  <h4
                    style={{
                      fontWeight: "300",
                      fontSize: "20px",
                      margin: "0",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    MobileNumber-{selectedTeacher.mobileNumber}
                  </h4>
                  <h4
                    style={{
                      fontWeight: "300",
                      fontSize: "20px",
                      margin: "0",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    Messege-{selectedTeacher.messege}
                  </h4> */}
                </>
              ) : (
                ""
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              />
            </Paper>
          </>
        ) : (
          <Paper
            style={{
              height: 500,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3 style={{ padding: 0 }}>You Have No Data To Show</h3>
          </Paper>
        )}
      </Layout>
    );
  }
}

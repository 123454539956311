import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import readingTime from "reading-time";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { withStyles } from "@material-ui/core/styles";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { text: "" }; // You can also pass a Quill Delta here, when you use edit mode for any blog, you can simply pass old delta here.
    this.editor = React.createRef();
  }

  modules = {
    toolbar: [
      ["bold", "italic", "underline"],

      [{ align: [] }],
      [({ list: "ordered" }, { list: "bullet" })],
      [{ font: [] }],
      // [{ header: [1, 2, 3, 4, 5, false] }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["image", "link"],
    ],
  };

  formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "align",
    "link",
    "image",
    "color",
  ];

  componentWillMount() {
    if (this.props.selectedBlogData) {
      this.setState({
        text: this.props.blogDescription
          ? this.props.blogDescription
          : this.props.selectedBlogData.description
          ? this.props.selectedBlogData.description
          : "",
      });
    }
  }
  componentDidMount() {
    setInterval(() => {
      // This will generate delta everytime when you call it.
      if (this.editor.current) {
        console.log(this.editor.current.getEditor().getContents().ops);
      }
    }, 10000);
  }

  handleChange = (value) => {
    this.setState({ text: value }, () => {
      const array = [];
      console.log(
        value,
        this.editor.current.getEditor().getContents().ops,
        "jjjjjjjj"
      );
      const totalText = [];
      const deltaOps = this.editor.current.getEditor().getContents().ops;

      const cfg = {};

      const converter = new QuillDeltaToHtmlConverter(deltaOps, cfg);

      const html = converter.convert();
      console.log(
        "anuuuuuuuuyuuuuuuuuuuuuu",
        this.editor.current.getEditor().getContents().ops
      );
      this.editor.current
        .getEditor()
        .getContents()
        .ops.map((data) => {
          if (data.insert.image) {
            // array.image = data.insert.image;
          } else {
            totalText.push(data.insert);
            console.log(data.insert, "ttttttttttt");
            console.log(data, "ppppppppppppp");
            // console.log(new QuillDeltaToHtmlConverter(data.insert), "jjj");
          }

          array.push(data);
        });
      console.log(
        totalText.join(" "),
        "totalText.texttotalText.texttotalText.text"
      );
      const time = readingTime(totalText.join(" "));
      console.log(totalText, time, "anu1111111111111111111111111");
      this.props.description(array, time);
    });
  };

  render() {
    return (
      <>
        <ReactQuill
          style={{
            width: "100%",
            height: "300px",
            color: "#404040",
            justifyContent: "space-between",
            fontFamily: "Poppins",
            border: "1px solid grey",
          }}
          // styles={false}
          ref={this.editor}
          value={this.state.text}
          modules={this.modules}
          formats={this.formats}
          onChange={this.handleChange}
          theme="snow"
        />
        {/* <style jsx>{`
          .ql-container.ql-snow {
            border-top: 0px;
            height: 150px;
          }
        `}</style> */}
      </>
    );
  }
}

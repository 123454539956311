import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { text: "" }; // You can also pass a Quill Delta here, when you use edit mode for any blog, you can simply pass old delta here.
    this.editor = React.createRef();
  }

  modules = {
    toolbar: [["image"]],
  };
  componentWillMount() {
    if (this.props.selectedBlogData) {
      this.setState({
        text: this.props.blogImage
          ? this.props.blogImage
          : this.props.selectedBlogData.imageUrls
          ? this.props.selectedBlogData.imageUrls
          : "",
      });
    }
  }
  //formats = ["image", "color"];
  componentDidMount() {
    setInterval(() => {
      // This will generate delta everytime when you call it.
      if (this.editor.current) {
        console.log(this.editor.current.getEditor().getContents().ops);
      }
    }, 10000);
  }

  handleChange = (value) => {
    this.setState({ text: value }, () => {
      const array = [];
      this.editor.current
        .getEditor()
        .getContents()
        .ops.map((data) => {
          //   if (data.insert.image) {
          //     array.image = data.insert.image;
          //   } else {
          //     array.text = data.insert;
          //   }
          array.push(data);
        });
      this.props.setBlogImageData(array);
    });
  };

  render() {
    return (
      <>
        <div style={{ border: "1px solid white" }}>
          <ReactQuill
            style={{
              width: "100%",
              border: " 1px solid white",
              objectFit: "cover",
            }}
            ref={this.editor}
            value={this.state.text}
            modules={this.modules}
            formats={this.formats}
            onChange={this.handleChange}
            theme="snow"
          />
        </div>
      </>
    );
  }
}

import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import StarBorder from "@material-ui/icons/StarBorder";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ClientIcon from "@material-ui/icons/AddCircleOutline";
import PostAdd from "@material-ui/icons/PostAdd";
import Tooltip from "@material-ui/core/Tooltip";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import { GlobalHotKeys } from "react-hotkeys";
import withUser from "../../hoc/withUser";
import StaticVersionDisplay from "./StaticVersionDisplay";
import SchoolIcon from "@material-ui/icons/School";
import SettingsIcon from "@material-ui/icons/Settings";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import ShortTextTwoToneIcon from "@material-ui/icons/ShortTextTwoTone";
import WebIcon from "@material-ui/icons/Web";
import PeopleAltTwoToneIcon from "@material-ui/icons/PeopleAltTwoTone";
import ContactPhoneTwoToneIcon from "@material-ui/icons/ContactPhoneTwoTone";
import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "absolute",
    bottom: 0,
    right: 5,
    width: 240,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

// const keyMap = {
//   DASHBOARD: { name: "Expand square area", sequence: "1" },
//   MODULES: { name: "Expand square area", sequence: "2" },
//   USERANDPERMISSION: { name: "Expand square area", sequence: "3" },
// };

function DrawerContent(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.open);
  const [innerMenu1, setInnerMenu1] = React.useState(false);
  const [innerMenu2, setInnerMenu2] = React.useState(false);
  const [innerMenu3, setInnerMenu3] = React.useState(false);
  const [innerMenu4, setInnerMenu4] = React.useState(false);
  const [innerMenu5, setInnerMenu5] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const handlePageTitle = (title, index) => setSelectedIndex(index);
  const handleDrawerOpen = () => {
    setOpen(true);
    if (props.setOpen) props.setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    if (props.setOpen) props.setOpen(false);
  };
  // const handleShortcut = {
  //   DASHBOARD: (e) => props.history.push("/dashboard"),
  //   MODULES: (e) => props.history.push("/modules"),
  //   TEACHERS: (e) => props.history.push("/teachers"),
  //   CLASSES: (e) => props.history.push("/classes"),
  //   INSTITUTE: (e) => props.history.push("/institute"),
  //   SETTINGS: (e) => props.history.push("/settings"),

  //   USERANDPERMISSION: (e) => props.history.push("/userandpermmision"),
  // };
  const selectedStyle = (path) => ({
    color: props.match.path.includes(path) ? "#1E32FA" : "",
  });
  // const selectedStyle = (path) => ({
  //   color:
  //     props.match.path === path
  //       ? "blue"
  //       : primaryPath.includes(props.match.path)
  //       ? "blue"
  //       : "",
  // });
  const listItems = [
    {
      path: "/dashboard",
      title: "Dashboard",
      access: "dashboard",
      icon: (path) => <PostAdd style={selectedStyle(path)} />,
    },
    {
      path: "/institute",
      title: "Institutes Details",
      access: "institutes",
      icon: (path) => <SchoolIcon style={selectedStyle(path)} />,
      childRoutes: [
        {
          path: "/institutes",
          title: "Institutes",
          icon: (path) => <MenuBookIcon style={selectedStyle(path)} />,
        },
        {
          path: "/contact",
          title: "Contact Details",
          icon: (path) => (
            <ContactPhoneTwoToneIcon style={selectedStyle(path)} />
          ),
        },
      ],
    },
    {
      path: "/allteacherslist",
      title: "Teachers List ",
      access: "allteacherslist",
      icon: (path) => <WebIcon style={selectedStyle(path)} />,
    },
    {
      path: "/subjectmaster",
      title: "Master Subjects",
      access: "subjectmaster",
      icon: (path) => <WebIcon style={selectedStyle(path)} />,
      childRoutes: [
        {
          path: "/subjects",
          title: "Subjects",
          icon: (path) => <ShortTextTwoToneIcon style={selectedStyle(path)} />,
        },
      ],
    },

    {
      path: "/website",
      title: "Website",
      access: "website",
      icon: (path) => <WebIcon style={selectedStyle(path)} />,
      childRoutes: [
        {
          path: "/blogs",
          title: "Blogs",
          icon: (path) => <ShortTextTwoToneIcon style={selectedStyle(path)} />,
        },

        {
          path: "/websiteContacts",
          title: "Contact Details",
          icon: (path) => (
            <ContactPhoneTwoToneIcon style={selectedStyle(path)} />
          ),
        },
        {
          path: "/faq's",
          title: "FAQS Details",
          icon: (path) => (
            <ContactSupportOutlinedIcon style={selectedStyle(path)} />
          ),
        },
      ],
    },

    {
      path: "/settings",
      title: "Settings",
      access: "settings",
      icon: (path) => <SettingsIcon style={selectedStyle(path)} />,
      childRoutes: [
        {
          path: "/userandpermmision",
          title: "UsersAndPermission",
          icon: (path) => <PeopleAltTwoToneIcon style={selectedStyle(path)} />,
        },
      ],
    },
  ];

  return (
    <div>
      {/* <GlobalHotKeys keyMap={keyMap} handlers={handleShortcut} /> */}
      <div className={classes.listMainDiv}>
        <List disablePadding className={classes.root}>
          {listItems.map((list, index) => {
            if (props?.user?.access?.[list.access]) {
              return (
                <>
                  <ListItem
                    button
                    selected={selectedIndex === index}
                    onClick={
                      typeof list.childRoutes === "undefined"
                        ? (e) => {
                            e.preventDefault();
                            handlePageTitle(list.title, index);
                            props.history.push(list.path);
                          }
                        : list.title === "Institutes Details"
                        ? (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setInnerMenu1(!innerMenu1);
                          }
                        : list.title === "Teachers List"
                        ? (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setInnerMenu2(!innerMenu2);
                          }
                        : list.title === "Master Subjects"
                        ? (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setInnerMenu3(!innerMenu3);
                          }
                        : list.title === "Website"
                        ? (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setInnerMenu4(!innerMenu4);
                          }
                        : list.title === "Settings"
                        ? (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setInnerMenu5(!innerMenu5);
                          }
                        : null
                    }
                    style={selectedStyle(list.path)}
                  >
                    <ListItemIcon>
                      <Tooltip title={list.title} placement="right-start">
                        {list.icon(list.path)}
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={list.title} />
                    {typeof list?.childRoutes !== "undefined" &&
                    list?.title === "Institutes Details" ? (
                      innerMenu1 ? (
                        <ExpandLess />
                      ) : (
                        props.open && <ExpandMore />
                      )
                    ) : typeof list?.childRoutes !== "undefined" &&
                      list?.title === "Teachers List" ? (
                      innerMenu2 ? (
                        <ExpandLess />
                      ) : (
                        props.open && <ExpandMore />
                      )
                    ) : typeof list?.childRoutes !== "undefined" &&
                      list?.title === "Master Subjects" ? (
                      innerMenu3 ? (
                        <ExpandLess />
                      ) : (
                        props.open && <ExpandMore />
                      )
                    ) : typeof list?.childRoutes !== "undefined" &&
                      list?.title === "Website" ? (
                      innerMenu4 ? (
                        <ExpandLess />
                      ) : (
                        props.open && <ExpandMore />
                      )
                    ) : typeof list?.childRoutes !== "undefined" &&
                      list?.title === "Settings" ? (
                      innerMenu5 ? (
                        <ExpandLess />
                      ) : (
                        props.open && <ExpandMore />
                      )
                    ) : null}
                  </ListItem>
                  {typeof list?.childRoutes !== "undefined" ? (
                    <Collapse
                      in={
                        list?.title === "Institutes Details"
                          ? innerMenu1
                          : list?.title === "Teachers List"
                          ? innerMenu2
                          : list?.title === "Master Subjects"
                          ? innerMenu3
                          : list?.title === "Website"
                          ? innerMenu4
                          : list?.title === "Settings"
                          ? innerMenu5
                          : ""
                      }
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {list?.childRoutes?.map((e, i) => (
                          <ListItem
                            button
                            className={classes.nested}
                            onClick={(evt) => {
                              evt.preventDefault();
                              // handlePageTitle(e.title, i);
                              props.history.push(e.path);
                            }}
                          >
                            <ListItemIcon>{e?.icon(e?.path)}</ListItemIcon>
                            <ListItemText primary={e.title} />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  ) : null}
                </>
              );
            } else return null;
          })}
        </List>
      </div>

      <div className={classes.toolbar}>
        {props.open && <StaticVersionDisplay />}
        <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
          <Tooltip
            title={!props.open ? "Click to Expand" : "Click to Collapse"}
            placement="right-start"
          >
            {!props.open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </Tooltip>
        </IconButton>
      </div>
    </div>
  );
}

export default withRouter(withUser(DrawerContent));
